import userRole from "models/userRole";
import PropTypes from "prop-types";
import React from "react";
import {
	FaBuilding,
	FaHome,
	FaUsers,
	FaBook,
	FaShieldAlt,
	FaPlane,
	FaChartLine,
	FaRulerCombined,
	FaWhatsapp
} from "react-icons/fa";
import { FiDollarSign, FiPackage, FiHelpCircle } from "react-icons/fi";
import { BiNews } from "react-icons/bi";
import { BsCalendar } from "react-icons/bs";
import { FcMoneyTransfer, FcTodoList } from "react-icons/fc";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import SidebarCategory from "./SidebarCategory";
import SidebarLink from "./SidebarLink";
import { isInRole } from "helpers/auth";

function SidebarContent({ onClick }) {
	const hideSidebar = () => {
		onClick();
	};

	const { user } = useSelector(s => s.login);

	console.log(user);
	return (
		<div className="sidebar__content">
			<SidebarLink
				title="Dashboard"
				route="/"
				onClick={hideSidebar}
				icon={<FaHome />}
				// roles={[userRole.ADMIN]}
				roles={[userRole.HIDE]}
				user={user}
			/>

			<SidebarLink
				title="Expositores"
				route="/company/builder"
				user={user}
				roles={[userRole.BUILDER]}
				onClick={hideSidebar}
			/>

			<SidebarCategory
				title="Usuários"
				icon={<FaUsers />}
				isNew={false}
				roles={[
					userRole.ADMIN,
					userRole.MANAGER,
					userRole.BUILDER,
					userRole.RECEPTIONIST
				]}
				user={user}
			>
				<SidebarLink
					title="Cadastre sua equipe"
					route="/user/new"
					user={user}
					roles={[userRole.MANAGER, userRole.BUILDER]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Cadastrar"
					route="/user/new"
					user={user}
					roles={[userRole.ADMIN, userRole.RECEPTIONIST]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/user"
					user={user}
					roles={[
						userRole.ADMIN,
						userRole.MANAGER,
						userRole.BUILDER,
						userRole.RECEPTIONIST
					]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			<SidebarCategory
				// roles={[
				// 	userRole.ADMIN,
				// 	userRole.MANAGER,
				// 	userRole.USER
				// ]}
				roles={[userRole.HIDE]}
				title="Leads"
				user={user}
				icon={<FcTodoList />}
				isNew={false}
			>
				<SidebarLink
					title="Funil"
					route="/lead/funil"
					onClick={hideSidebar}
					user={user}
					roles={[userRole.MANAGER, userRole.USER]}
				/>
				<SidebarLink
					title="Relatório"
					route="/lead"
					onClick={hideSidebar}
					user={user}
					roles={[userRole.ADMIN, userRole.MANAGER, userRole.USER]}
				/>
			</SidebarCategory>

			<SidebarLink
				title="Pagamentos"
				route="/transaction"
				user={user}
				// roles={[
				// 	userRole.ADMIN,
				// 	userRole.MANAGER,
				// 	userRole.USER
				// ]}
				roles={[userRole.HIDE]}
				onClick={hideSidebar}
				icon={<FiDollarSign />}
			/>

			<SidebarCategory
				title="Cashbacks"
				icon={<FcMoneyTransfer />}
				isNew={false}
				// roles={[userRole.ADMIN, userRole.MANAGER]}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/cashback/new"
					user={user}
					roles={[userRole.ADMIN, userRole.MANAGER]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/cashback"
					user={user}
					roles={[userRole.ADMIN, userRole.MANAGER]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			<SidebarCategory
				title={isInRole(user, [userRole.ADMIN]) ? "Montadoras" : "Montadora"}
				icon={<FaUsers />}
				isNew={false}
				// roles={[userRole.ADMIN, userRole.MANAGER]}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Sua montadora"
					route="/builder/mine"
					user={user}
					roles={[userRole.MANAGER]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title={
						isInRole(user, [userRole.ADMIN])
							? "Consumo dos estandes"
							: "Consumo do estande"
					}
					route="/builder/consumption"
					user={user}
					roles={[userRole.ADMIN, userRole.MANAGER]}
					onClick={hideSidebar}
				/>
				{/* <SidebarLink
					title="Gerenciar"
					route="/builder/me"
					user={user}
					roles={[userRole.BUILDER]}
					onClick={hideSidebar}
				/> */}
				<SidebarLink
					title="Relatório"
					route="/builder"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			<SidebarLink
				title="Termo de responsabilidade"
				route="/company/responsibility"
				user={user}
				roles={[userRole.BUILDER, userRole.MANAGER]}
				onClick={hideSidebar}
			/>

			<SidebarLink
				title="Manual do expositor"
				// route="/company/builder"
				user={user}
				roles={[userRole.MANAGER, userRole.BUILDER]}
				onClick={() => {
					window.open(
						"https://construirai.s3.amazonaws.com/files/Manual+do+expositor+-+Feira+Construir+A%C3%AD.pdf",
						"_blank"
					);
					hideSidebar();
				}}
			/>

			<SidebarLink
				title="Dados para transporte"
				// route="/company/builder"
				user={user}
				roles={[userRole.MANAGER, userRole.BUILDER]}
				onClick={() => {
					window.open(
						"https://construirai.s3.amazonaws.com/files/Dados+para+Transporte+-+Feira+Construir+A%C3%AD.pdf",
						"_blank"
					);
					hideSidebar();
				}}
			/>

			<SidebarCategory
				title="Prestadores de serviço"
				icon={<FaBuilding />}
				isNew={false}
				roles={[userRole.MANAGER]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route={`/Cadastro/provider/${user?.company?._id}`}
					user={user}
					roles={[userRole.MANAGER]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/ServiceProvider"
					user={user}
					roles={[userRole.MANAGER]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			<SidebarLink
				title="Arquiteto Oficial"
				route="/builder/architect"
				user={user}
				roles={[userRole.MANAGER]}
				onClick={hideSidebar}
				icon={<FaRulerCombined />}
			/>

			<SidebarCategory
				title={"Montadora Oficial"}
				icon={<FaUsers />}
				isNew={false}
				roles={[userRole.MANAGER]}
				user={user}
			>
				<SidebarLink
					title="18m²"
					route="/builder/official/18"
					user={user}
					roles={[userRole.MANAGER]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="22.5m²"
					route="/builder/official/22-5"
					user={user}
					// roles={[userRole.MANAGER]}
					roles={["hide"]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="27m²"
					route="/builder/official/27"
					user={user}
					// roles={[userRole.MANAGER]}
					roles={["hide"]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="30m²"
					route="/builder/official/30"
					user={user}
					// roles={[userRole.MANAGER]}
					roles={["hide"]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="36m²"
					route="/builder/official/36"
					user={user}
					roles={[userRole.MANAGER]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="45m²"
					route="/builder/official/45"
					user={user}
					roles={[userRole.MANAGER]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="54m²"
					route="/builder/official/54"
					user={user}
					roles={[userRole.MANAGER]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			<SidebarLink
				title="Seguradora Oficial"
				route="/builder/safe"
				user={user}
				roles={[userRole.MANAGER]}
				onClick={hideSidebar}
				icon={<FaShieldAlt />}
			/>

			<SidebarLink
				title="Agência de Marketing"
				route="/builder/marketing"
				user={user}
				roles={[userRole.MANAGER]}
				onClick={hideSidebar}
				icon={<FaChartLine />}
			/>

			<SidebarLink
				title="Agência de Viagens"
				route="/builder/trip"
				user={user}
				roles={[userRole.MANAGER]}
				onClick={hideSidebar}
				icon={<FaPlane />}
			/>

			<SidebarLink
				title="Gráfica Oficial"
				route="/builder/graphic"
				user={user}
				roles={[userRole.MANAGER]}
				onClick={hideSidebar}
				// icon={<SiTaichigraphics />}
			/>

			<SidebarLink
				title="Paisagismo Oficial"
				route="/builder/landscaping"
				user={user}
				// roles={[userRole.MANAGER]}
				roles={["hide"]}
				onClick={hideSidebar}
				// icon={<FaPlane />}
			/>

			<SidebarLink
				title="Cervejaria Oficial"
				route="/builder/landscaping"
				user={user}
				// roles={[userRole.MANAGER]}
				roles={["hide"]}
				onClick={hideSidebar}
				// icon={<FaPlane />}
			/>

			<SidebarCategory
				title="Expositores"
				icon={<FaBuilding />}
				isNew={false}
				roles={[userRole.ADMIN, userRole.PROJECT_MANAGER]}
				// roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/company/new"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/company"
					user={user}
					roles={[userRole.ADMIN, userRole.PROJECT_MANAGER]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			<SidebarCategory
				title="Prestadores de serviço"
				icon={<FaBuilding />}
				isNew={false}
				// roles={[userRole.ADMIN, userRole.MANAGER]}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/ServiceProvider/new"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/ServiceProvider"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			<SidebarCategory
				title="Items de consumo"
				icon={<FaBuilding />}
				isNew={false}
				// roles={[userRole.ADMIN]}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/Consumption/Item/New"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/Consumption/Item"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			<SidebarCategory
				title="Categorias"
				icon={<FiPackage />}
				isNew={false}
				// roles={[userRole.ADMIN]}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/productCategory/new"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/productCategory"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>
			<SidebarCategory
				title="Produtos"
				icon={<FiPackage />}
				isNew={false}
				// roles={[userRole.ADMIN]}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/product/new"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/product"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>
			<SidebarCategory
				title="Noticias"
				icon={<BiNews />}
				isNew={false}
				// roles={[userRole.ADMIN]}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/news/new"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/news"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>
			<SidebarCategory
				title="Eventos"
				icon={<BsCalendar />}
				isNew={false}
				roles={[userRole.ADMIN]}
				// roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/event/new"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/event"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			<SidebarLink
				title="Orçamentos"
				route="/budget"
				user={user}
				// roles={[userRole.ADMIN, userRole.MANAGER, userRole.USER]}
				roles={[userRole.HIDE]}
				onClick={hideSidebar}
				icon={<AiOutlineDollarCircle />}
			/>

			<SidebarCategory
				title="Obras"
				icon={<FaBook />}
				isNew={false}
				// roles={[userRole.ADMIN, userRole.MANAGER]}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/construction/create"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/construction"
					user={user}
					roles={[userRole.ADMIN, userRole.MANAGER]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>
			<SidebarCategory
				title="Cursos"
				icon={<FaBook />}
				isNew={false}
				// roles={[userRole.ADMIN]}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="Cadastrar"
					route="/courses/create"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Relatório"
					route="/courses"
					user={user}
					roles={[userRole.ADMIN]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>
			{/*lado do cliente*/}
			<SidebarCategory
				title="Cursos"
				icon={<FaBook />}
				isNew={false}
				roles={[userRole.HIDE]}
				user={user}
			>
				<SidebarLink
					title="App"
					route="/cursos/user"
					user={user}
					roles={[userRole.MANAGER]}
					onClick={hideSidebar}
				/>
				<SidebarLink
					title="Vendas"
					route="/cursos/user/sales"
					user={user}
					roles={[userRole.MANAGER]}
					onClick={hideSidebar}
				/>
			</SidebarCategory>

			{/* <SidebarLink
				title={
					<div>
						Ajuda <FaWhatsapp />
					</div>
				}
				onClick={() => {
					window.open("https://wa.me/554792052220", "_blank");
				}}
				icon={<FiHelpCircle />}
			/> */}
		</div>
	);
}

SidebarContent.propTypes = {
	changeToDark: PropTypes.func.isRequired,
	changeToLight: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired
};

export default SidebarContent;
