import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";
import TopBarNotification from "./TopBarNotification";
import TopbarStore from "./TopbarStore";
import ls from "Localization";

class Topbar extends PureComponent {
	static propTypes = {
		changeMobileSidebarVisibility: PropTypes.func.isRequired,
		changeSidebarVisibility: PropTypes.func.isRequired
	};

	render() {
		const {
			changeMobileSidebarVisibility,
			changeSidebarVisibility
		} = this.props;

		return (
			<div className="topbar">
				<div className="topbar__wrapper">
					<div className="topbar__left">
						<TopbarSidebarButton
							changeMobileSidebarVisibility={changeMobileSidebarVisibility}
							changeSidebarVisibility={changeSidebarVisibility}
						/>
						<Link className="topbar__logo" to="/">
							<span className="account__logo" />
							{/* <span>{ls.appName}</span> */}
						</Link>
					</div>
					<div className="topbar__right">
						<TopBarNotification />
						<TopbarStore />
						<TopbarProfile />
					</div>
				</div>
			</div>
		);
	}
}

export default Topbar;
