import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";

const WizardFormFirstPage = props => {
	const { handleSubmit, type } = props;

	const handleSelect = event => {
		handleSubmit(event.target.name);
	};

	return (
		<div>
			<div className="flex-center">
				<h3 className="account__title">Selecione um método:</h3>
			</div>
			{type ? (
				<>
					<button
						name="qrCode"
						className="btn btn-primary account__btn account__btn--small"
						onClick={handleSelect}
					>
						QR-Code
					</button>
					<button
						name="reader"
						className="btn btn-primary account__btn account__btn--small"
						onClick={handleSelect}
					>
						Leitor Cód. Barras/QR-Code
					</button>
				</>
			) : (
				<>
					<button
						name="document"
						className="btn btn-primary account__btn account__btn--small"
						onClick={handleSelect}
					>
						CPF
					</button>
					<button
						name="email"
						className="btn btn-primary account__btn account__btn--small"
						onClick={handleSelect}
					>
						E-mail
					</button>
					<button
						name="qrCode"
						className="btn btn-primary account__btn account__btn--small"
						onClick={handleSelect}
					>
						Ler QR-Code com a câmera
					</button>
					<button
						name="reader"
						className="btn btn-primary account__btn account__btn--small"
						onClick={handleSelect}
					>
						Digitar código do ingresso
					</button>
				</>
			)}
		</div>
	);
};

export default reduxForm({
	form: "self_service_form", // <------ same form name
	destroyOnUnmount: false, // <------ preserve form data
	validate
})(WizardFormFirstPage);
