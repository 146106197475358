import { validateEmail } from "helpers/email";
import {
	CNPJValidation,
	CPFValidation,
	dateValidation,
	PhoneValidation
} from "helpers/validators";

const validate = (values, step) => {
	console.log("values", values);
	const errors = {};

	if (step === 1) {
	}

	if (step === 2) {
		if (!values.name) {
			errors["name"] = "Informe seu nome";
		}

		if (!values.lastName) {
			errors["lastName"] = "Informe seu sobrenome";
		}

		if (!values.email) {
			errors["email"] = "Informe seu e-mail";
		} else if (!validateEmail(String(values["email"]))) {
			errors["email"] = "Informe um e-mail válido";
		}

		if (!values.phone) {
			errors["phone"] = "Informe seu telefone";
		} else if (
			values.type === "importer"
				? !PhoneValidation("", String(values["phone"]))
				: !PhoneValidation("55", String(values["phone"]))
		) {
			errors["phone"] = "Informe um telefone válido";
		}

		if (values.type !== "importer") {
			if (!values.profession) {
				errors["profession"] = "Informe sua profissão";
			}

			if (!values.cpf) {
				errors["cpf"] = "Informe seu cpf";
			} else if (!CPFValidation(String(values["cpf"]))) {
				errors["cpf"] = "Informe um cpf válido";
			}

			if (!values.birthDate) {
				errors["birthDate"] = "Informe sua data de nascimento";
			} else {
				if (!dateValidation(values.birthDate, "DD/MM/YYYY")) {
					errors["birthDate"] = "Informe uma data de nascimento válida";
				}
			}

			if (!values.zipCode) {
				errors["zipCode"] = "Informe seu CEP";
			} else if (String(values.zipCode).match(/\d/g).length !== 8) {
				errors["zipCode"] = "Informe um CEP válido";
			}
		} else {
			if (!values.country) {
				errors["country"] = "Informe seu país";
			}
		}

		if (!values.city) {
			errors["city"] = "Informe sua cidade";
		}

		if (!values.state) {
			errors["state"] = "Informe seu estado";
		}

		if (["exhibitor", "store", "press"].includes(values.type)) {
			if (!values.companyName) {
				errors["companyName"] = "Informe o nome da empresa";
			}

			if (!values.cnpj) {
				errors["cnpj"] = "Informe o CNPJ da empresa";
			} else if (!CNPJValidation(String(values["cnpj"]))) {
				errors["cnpj"] = "Informe um CNPJ válido";
			}
		}

		if (values.type === "professional") {
			if (!values.architect && !values.interiorDecorator) {
				errors["architect"] = "Informe sua profissão";
			}
		}

		if (!values.knowAbout) {
			errors["knowAbout"] = "Informe como nos conheceu";
		}

		if (!values.lgpd) {
			errors["lgpd"] = "Você precisa aceitar os termos de uso";
		}
	}

	if (step === 3) {
		if (!values.gender) {
			errors["gender"] = "Informe seu gênero";
		}

		if (!values.professionalFiles || values.professionalFiles.length === 0) {
			errors["professionalFiles"] = "Informe ao menos um arquivo";
		}
	}

	console.log("errors", errors);
	return errors;
};

export default validate;
