import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const ISSUE_CERTIFICATE = "[CERTIFICATE ISSUE] ISSUE_CERTIFICATE";
export const ISSUE_CERTIFICATE_ERROR =
	"[CERTIFICATE ISSUE] ISSUE_CERTIFICATE_ERROR";
export const ISSUE_CERTIFICATE_SUCCESS =
	"[CERTIFICATE ISSUE] ISSUE_CERTIFICATE_SUCCESS";

export const SET_VALUE = "[CERTIFICATE ISSUE] SET_VALUE";
export const CLEAR_VALUES = "[CERTIFICATE ISSUE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function issueCertificate(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ISSUE_CERTIFICATE
		});
		try {
			const { login } = getState();

			let response = await api.sendPost(`/certificate/issue`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ISSUE_CERTIFICATE_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: ISSUE_CERTIFICATE_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: ISSUE_CERTIFICATE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
