import React, { useEffect, useCallback, useState, useMemo } from "react";
import { reset, change, formValueSelector } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { findUser, checkInOut } from "./actions";
import _ from "lodash";
import SelfServiceForm from "./components/SelfServiceForm";
import Alert from "../../shared/components/Alert";
import actions from "redux/actions";
import { generateUserTag } from "helpers/tag";
import ls from "Localization";
import { getEventPublic } from "containers/Events/containers/Report/actions";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const SelfService = ({ match }) => {
	const dispatch = useDispatch();

	const { type, event } = match?.params || {};

	const [error, setError] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState(null);

	const { message } = useSelector(s => s.login);

	const state = useSelector(state => state);

	const selector = formValueSelector("self_service_form");

	const cpfValue = useMemo(() => {
		console.log("Selector: ", page);
		return selector(state, page);
	}, [state, page]);

	console.log("CPF Value: ", cpfValue);
	const handleKeyPress = useCallback(
		button => {
			console.log("CPF Value: ", cpfValue, button);

			let currentValue = cpfValue || "";

			if (button === "{bksp}") {
				dispatch(change("self_service_form", page, currentValue.slice(0, -1)));
			} else {
				dispatch(change("self_service_form", page, currentValue + button));
			}
		},
		[page, cpfValue]
	);

	const handleGenerateQrCode = async data => {
		await generateUserTag(data, true);

		if (!["reader"].includes(page)) setPage(1);

		setError("");
	};

	const handleDismiss = () => {
		dispatch(actions.login.setValue({ message: "" }));
	};

	const onSubmit = data => {
		console.log("Data: ", data);
		setError("");

		try {
			const payload =
				(data.document ? data.document.match(/\d/g)?.join("") : "") ||
				data.email ||
				data.result;

			console.log("Payload: ", payload);

			if (["in", "out"].includes(type?.toLowerCase())) {
				dispatch(
					checkInOut(
						event,
						payload,
						type.toLowerCase() === "in" ? 1 : 2,
						(err, result) => {
							dispatch(reset("self_service_form"));
							if (err) {
								setError(err.default || err.error || err.message || err);

								dispatch(actions.login.setValue({ message: "Não encontrado" }));

								if (typeof window !== "undefined") {
									const audio = new Audio("/beep/out.mp3");
									audio.play();
								}
							} else {
								console.log("Processing result: ", result);

								if (typeof window !== "undefined") {
									const audio = new Audio("/beep/in.mp3");
									audio.play();
								}

								setData(result?.user);
							}
						}
					)
				);
			} else {
				dispatch(
					findUser(payload, (err, result) => {
						dispatch(reset("self_service_form"));
						if (err) {
							setError(err.default || err.error || err.message || err);

							setTimeout(() => {
								handleReload();
							}, 5000);
						} else {
							console.log("Processing result: ", result);

							if (result && result._id) {
								handleGenerateQrCode(result);
							}
						}
					})
				);
			}
		} catch (err) {
			console.error("There was an error processing the submission: ", err);
		}
	};

	const handleReload = () => {
		location.reload();
	};

	return (
		<div className="account">
			{message && (
				<div
					style={{
						position: "absolute",
						right: 16,
						top: 16
					}}
				>
					<Alert
						color="info"
						className="alert--bordered"
						icon
						onDismiss={handleDismiss}
					>
						<p>
							<span className="bold-text">{message}</span>
						</p>
					</Alert>
				</div>
			)}
			<div className="account__wrapper">
				<div
					className="account__card"
					style={{
						paddingBottom: 0
					}}
				>
					<div className="account__logo__head">
						<div className="account__logo" />
					</div>

					<h3 className="account__title">{ls[type]}</h3>

					<SelfServiceForm
						onSubmit={onSubmit}
						page={page}
						type={type}
						setPage={setPage}
					/>
				</div>
				{page === "document" && (
					<div
						style={{
							marginTop: 16
						}}
					>
						<Keyboard
							onKeyPress={handleKeyPress}
							layout={{
								default: ["1 2 3", "4 5 6", "7 8 9", "0 {bksp}"],
								shift: [
									"! @ #",
									"$ % ^",
									"& * (",
									") {bksp}",
									"{shift} {space}"
								]
							}}
							display={{
								"{bksp}": "⌫",
								"{space}": " "
							}}
						/>
					</div>
				)}

				{page === "email" && (
					<div
						style={{
							marginTop: 16,
							marginLeft: "-20%",
							marginRight: "-20%"
						}}
					>
						<Keyboard
							onKeyPress={handleKeyPress}
							layout={{
								default: [
									"1 2 3 4 5 6 7 8 9 0",
									"q w e r t y u i o p",
									"a s d f g h j k l",
									"z x c v b n m @ .",
									"@gmail.com @hotmail.com @yahoo.com .com .br",
									"{space} {bksp}"
								],
								shift: [
									"! @ # $ % ^ & * ( )",
									"Q W E R T Y U I O P",
									"A S D F G H J K L",
									"Z X C V B N M @ .",
									"{space} {bksp}"
								]
							}}
							display={{
								"{bksp}": "⌫",
								"{space}": " "
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default SelfService;
