import React, { useCallback, useRef, useState } from "react";
import {
	ButtonGroup,
	ButtonToolbar,
	Button,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import userStatus from "models/userStatus";
import { CPF } from "components/NumberFormat";
import { MdDelete, MdEdit, MdRestore } from "react-icons/md";
import { FaAward, FaQrcode } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineImport } from "react-icons/ai";
import { FiLink } from "react-icons/fi";
import Delete from "../Delete";
import Edit from "../Edit";
import TooltipComponent from "components/TooltipComponent";
import { useMemo } from "react";
import { getExcelData } from "components/Report/export";
import * as XLSX from "xlsx";

import Swal from "sweetalert2";

import { saveAs } from "file-saver";

import ReactExport from "react-export-excel";
import userProfessions from "models/userProfessions";
import DateSelectFilter from "components/Report/DateSelectorFilter";
import { generateUserTag } from "helpers/tag";
import userRole from "models/userRole";
import { isInRole } from "helpers/auth";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let fetchDataDebounced = null;

function Report({ history, companyId }) {
	const dispatch = useDispatch();
	const table = useRef(null);
	const fileInputRef = useRef(null);
	const checkInInputRef = useRef(null);
	const [exportComponent, setExport] = useState(null);

	const state = useSelector(s => s.users);
	const { user } = useSelector(s => s.login);

	const isAdmin = useMemo(() => {
		if (!user) return false;

		return user.role.toLowerCase() === "admin";
	}, [user]);

	const isReceptionist = useMemo(() => {
		if (!user) return false;

		return isInRole(user, [userRole.RECEPTIONIST]);
	}, [user]);

	const isManager = useMemo(() => {
		if (!user) return false;

		return user.role.toLowerCase() === "manager";
	}, [user]);

	const isBuilder = useMemo(() => {
		if (!user) return false;

		return user.role.toLowerCase() === "builder";
	}, [user]);

	const columns = useMemo(
		() =>
			[
				isAdmin
					? {
							Header: "Data criação",
							id: "createdAt",
							accessor: c =>
								c.createdAt ? dayjs(c.createdAt).format(ls.dateTimeFormat) : "",
							width: 120,
							Filter: p => <DateSelectFilter {...p} />
					  }
					: null,
				isAdmin
					? {
							Header: "Data atualização",
							id: "updatedAt",
							accessor: c =>
								c.updatedAt ? dayjs(c.updatedAt).format(ls.dateTimeFormat) : "",
							width: 120,
							Filter: p => <DateSelectFilter {...p} />
					  }
					: null,
				{
					Header: "Nome",
					accessor: "fullName"
				},
				{
					Header: "E-mail",
					accessor: "email",
					width: 200
				},
				{
					Header: "CPF",
					id: "document",
					accessor: c =>
						c.document ? (
							<CPF displayType="text" value={c.document} />
						) : (
							"Não definido"
						),
					width: 120
				},
				isAdmin
					? {
							Header: "Celular",
							id: "phoneNumber",
							accessor: c =>
								c.nationalPhoneNumber ? c.nationalPhoneNumber : "Não definido",
							width: 120
					  }
					: null,
				!companyId
					? {
							Header: "Expositor/Montadora",
							id: "company",
							accessor: c => (c.company ? c.company.name : "Não definido"),
							width: 120
					  }
					: null,
				(isAdmin || isReceptionist) && !companyId
					? {
							Header: "Tipo",
							id: "companyType",
							accessor: c => {
								if (c.company?.companyType === "E") return "Expositor";
								if (c.company?.companyType === "M") return "Montadora";
								if (c.company?.companyType === "S") return "P. Serviço";
								return "Não definido";
							},
							Filter: ({ filter, onChange }) => (
								<select
									onChange={event => onChange(event.target.value)}
									style={{ width: "100%" }}
									value={filter ? filter.value : "all"}
								>
									<option value="">Todos</option>
									<option value="E">Expositor</option>
									<option value="M">Montadora</option>
									<option value="S">Prestador Serviço</option>
								</select>
							),
							width: 160
					  }
					: null,
				{
					Header: "Cargo/Profissão",
					id: "profession",
					accessor: c =>
						userProfessions[c.profession] || c.profession || "Não definido",
					width: 120
				},
				{
					Header: "Setor",
					id: "sector",
					accessor: c => c.sector || "Não definido",
					width: 120
				},
				{
					Header: "Código",
					id: "externalId",
					accessor: c => c.externalId || "Não definido",
					width: 120
				},
				isAdmin
					? {
							Header: "Função",
							id: "role",
							accessor: c => ls[userRole[c.role]],
							width: 100,
							Filter: ({ filter, onChange }) => (
								<select
									onChange={event => onChange(event.target.value)}
									style={{ width: "100%" }}
									value={filter ? filter.value : "all"}
								>
									<option value="">Todos</option>
									{Object.keys(userRole).map(c => (
										<option key={c} value={c}>
											{ls[userRole[c]]}
										</option>
									))}
								</select>
							)
					  }
					: null,
				{
					Header: "Status",
					id: "status",
					accessor: c => ls[userStatus[c.status]],
					width: 100,
					Filter: ({ filter, onChange }) => (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter ? filter.value : "all"}
						>
							<option value="">Todos</option>
							{Object.keys(userStatus).map(c => (
								<option key={c} value={c}>
									{ls[userStatus[c]]}
								</option>
							))}
						</select>
					)
				},
				{
					Header: "Ações",
					width: 120,
					filterable: false,
					id: "actions",
					Cell: props => {
						return (
							<ButtonToolbar>
								<ButtonGroup className="btn-group--icons" dir="ltr">
									{isAdmin || isReceptionist ? (
										<>
											<Button
												color="primary"
												onClick={() => handleGenerateQrCode(props.original)}
											>
												<FaQrcode />
											</Button>
										</>
									) : null}

									<Button
										color="primary"
										onClick={() => handleEditClick(props.original)}
									>
										<MdEdit style={{ margin: 0 }} />
									</Button>

									{isAdmin ? (
										<>
											<Button
												color="secondary"
												onClick={() => {
													console.log("Send welcome and password");
													handleSendWelcomeAndPasswordClick(props.original);
												}}
											>
												<MdRestore style={{ margin: 0 }} />
											</Button>
											<Button
												color="cancel"
												onClick={() => handleDeleteClick(props.original)}
											>
												<MdDelete style={{ margin: 0 }} />
											</Button>
										</>
									) : null}
								</ButtonGroup>
							</ButtonToolbar>
						);
					}
				}
			].filter(c => c),
		[isAdmin, isReceptionist, isManager, isBuilder, companyId]
	);

	const handleFileInputClick = () => {
		fileInputRef.current.click();
	};

	const handleCheckInInputClick = () => {
		checkInInputRef.current.click();
	};

	const handleCheckInExcel = (file, type) => {
		if (!file) return;

		const reader = new FileReader();
		reader.onload = async e => {
			const data = new Uint8Array(e.target.result);

			fileInputRef.current.value = ""; // Limpar o input

			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet);

			console.log(jsonData);

			// Processar os dados para extrair as informações desejadas
			const processedData = jsonData.map(row =>
				String(row["CPF"])
					?.match(/\d/g)
					?.join("")
					.padStart(11, "0")
			);

			dispatch(
				actions.checkInUsers(processedData, (err, models) => {
					if (err) {
						console.log(err);
						alert(err);
					} else {
						generateUserTag(models);
					}
				})
			);
		};

		reader.readAsArrayBuffer(file);
	};

	const handleImportExcel = (file, type) => {
		if (!file) return;

		const reader = new FileReader();
		reader.onload = async e => {
			const data = new Uint8Array(e.target.result);

			fileInputRef.current.value = ""; // Limpar o input

			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet);

			console.log(jsonData);
			// Processar os dados para extrair as informações desejadas
			const processedData = jsonData
				.map(row => ({
					fullName: String(row["Nome"])?.trim(),
					name: String(row["Nome"])?.trim(),
					lastName: String(row["Sobrenome"])?.trim(),
					email: String(row["E-mail"])?.trim(),
					badgeName: String(row["Crachá"] || "")?.trim(),
					phoneNumber: String(row["Telefone"])
						?.match(/\d/g)
						?.join(""), // Remove caracteres não numéricos
					cpf: String(row["CPF"])
						?.match(/\d/g)
						?.join(""), // Remove caracteres não numéricos
					// companyName: String(row["Empresa"])?.trim(),
					// profession: String(row["Cargo"] || "")?.trim() || undefined,
					companyName:
						String(row["Empresa/Instituição"] || "")?.trim() || undefined,
					// companySocialName: String(row["companySocialName"])?.trim(),
					companyDocument: String(row["CNPJ"])
						?.match(/\d/g)
						?.join(""),
					// cnpj: String(row["cnpj"])
					// 	?.match(/\d/g)
					// 	?.join(""),
					city: String(row["Cidade"] || "")?.trim(),
					state: String(row["UF"] || "")?.trim(),
					// type: "exhibitor",
					externalId: String(row["ID Ingresso"] || "")?.trim(),
					checkInAt: row["Data Check-in (*)"]
						? dayjs(row["Data Check-in (*)"]).toDate()
						: undefined,
					ticketType: String(row["Lote"] || "").split(" ")[0]
				}))
				.filter(c => c.email && c.fullName && (c.cpf || c.externalId));

			for (const user of processedData) {
				user.name = user.fullName.split(" ")[0];
				user.lastName = user.fullName
					.split(" ")
					.slice(1)
					.join(" ");

				if (!user.lastName) user.lastName = " ";
			}

			console.log(processedData);

			dispatch(
				actions.importUsers(processedData, err => {
					if (err) {
						console.log(err);
						alert(err);
					} else {
						alert("Importação realizada com sucesso!");
					}
				})
			);
		};

		reader.readAsArrayBuffer(file);
	};

	const handleImportExcel2 = (file, type) => {
		if (!file) return;

		const reader = new FileReader();
		reader.onload = async e => {
			const data = new Uint8Array(e.target.result);

			fileInputRef.current.value = ""; // Limpar o input

			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet);

			console.log(jsonData);

			// Processar os dados para extrair as informações desejadas
			const processedData = jsonData
				.map(row => ({
					profissao: row["your-profissao"],
					profissao2: row["your-profissao2"],
					cpf: row["your-cpf"],
					name: row["your-name"],
					email: row["your-email"],
					numero: row["your-numero"],
					celular: row["your-celular"],
					genero: row["your-genero"],
					cidade: row["your-cidade"],
					estado: row["your-estado"],
					objetivo: row["your-objetivo"],
					comosoube: row["your-comosoube"],
					receberinformacoes: row["your-receberinformacoes"],
					autorizacao: row["your-autorizacao"],
					createdAt: dayjs(row["record_date"], "YYYY/MM/DD HH:mm").toDate()
				}))
				.filter(c => c.email && c.name);

			console.log(processedData);

			dispatch(
				actions.importUsers(processedData, err => {
					if (err) {
						console.log(err);
						alert(err);
					} else {
						alert("Importação realizada com sucesso!");
					}
				})
			);
		};

		reader.readAsArrayBuffer(file);
	};

	const handleFetchData = useCallback(
		tableState => {
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (fetchDataDebounced) {
				fetchDataDebounced.cancel();
			}

			filtered = filtered || [];

			if (companyId) {
				filtered = [
					...filtered,
					{
						id: "company",
						value: companyId
					}
				];
			}

			const createdAt = filtered.find(c => c.id === "createdAt");

			if (createdAt?.value) {
				filtered = filtered.filter(c => c.id !== "createdAt");

				if (createdAt?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: createdAt.value.startDate
					});
				}

				if (createdAt?.value.endDate)
					filtered.push({
						id: "endDate",
						value: createdAt.value.endDate
					});
			}

			const updatedAt = filtered.find(c => c.id === "updatedAt");

			if (updatedAt?.value) {
				filtered = filtered.filter(c => c.id !== "updatedAt");

				if (updatedAt?.value.startDate) {
					filtered.push({
						id: "startDateUpdated",
						value: updatedAt.value.startDate
					});
				}

				if (updatedAt?.value.endDate)
					filtered.push({
						id: "endDateUpdated",
						value: updatedAt.value.endDate
					});
			}

			let sort = sorted[0] || {};

			if (!sorted) sorted = [];

			fetchDataDebounced = _.debounce(() => {
				dispatch(
					actions.getUsers(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sort.id,
						sort.desc,
						toExport,
						callback
					)
				);
			}, 500);

			fetchDataDebounced();
		},
		[companyId]
	);

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handlePrizeDraw = async () => {
		let result = await Swal.fire({
			title: "Iniciar sorteio",
			icon: "info",
			html: "Deseja iniciar o sorteio da viagem com acompanhante para Milão?",
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: '<i class="fa fa-thumbs-up"></i> Sortear!',
			cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancelar'
		});

		if (result?.isConfirmed) {
			dispatch(
				actions.prizeDraw("milan", async (err, model) => {
					if (err) {
						alert(err);
					} else {
						var blob = new Blob(
							[
								"Nome completo, E-mail, Telefone\n" +
									`${model.fullName}, ${model.email}, ${model.internationalPhoneNumber}`
							],
							{
								type: "text/csv;charset=utf-8"
							}
						);

						saveAs(blob, "sorteio-milan.csv");

						await Swal.fire({
							title: "Sorteio realizado!",
							html: `O(A) ganhador(a) da viagem para Milão foi <strong>${model.fullName}</strong>`,
							icon: "success",
							confirmButtonText: "Próximo sorteio"
						});

						result = await Swal.fire({
							title: "Iniciar sorteio",
							icon: "info",
							html: "Deseja iniciar o sorteio da viagem para Gramado?",
							showCloseButton: true,
							showCancelButton: true,
							focusConfirm: false,
							confirmButtonText: '<i class="fa fa-thumbs-up"></i> Sortear!',
							cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancelar'
						});

						if (result.isConfirmed) {
							dispatch(
								actions.prizeDraw("gramado", async (err, model) => {
									if (err) {
										alert(err);
									} else {
										var blob = new Blob(
											[
												"Nome completo, E-mail, Telefone, Profissão\n" +
													`${model.fullName}, ${model.email}, ${model.internationalPhoneNumber}, ${model.profession}`
											],
											{
												type: "text/csv;charset=utf-8"
											}
										);

										saveAs(blob, "gramado.csv");

										await Swal.fire({
											title: "Sorteio realizado!",
											html: `O(A) ganhador(a) da viagem para Gramado foi <strong>${model.fullName}</strong>`,
											icon: "success",
											confirmButtonText: "Finalizar"
										});
									}
								})
							);
						}
					}
				})
			);
		}
	};

	const handleSendWelcomeAndPasswordClick = data => {
		dispatch(
			actions.sendWelcomePassword(data._id, err => {
				if (err) {
					alert(err);
				} else {
					alert("E-mail de bem vindo e senha enviado com sucesso!");
				}
			})
		);
	};

	const handleDeleteClick = data => {
		dispatch(
			actions.setValue({
				showDeleteForm: data
			})
		);
	};

	const handleEditClick = data => {
		dispatch(
			actions.setValue({
				showEditForm: true,
				item: data
			})
		);
	};

	const handleGenerateLink = useCallback(() => {
		const data = user.company;

		const type = { E: "expositor", S: "provider", M: "builder" }[
			data.companyType
		];

		const urlGenerate = `${process.env.REACT_APP_APP_URL}/cadastro/${type}/${data._id}`;

		navigator.clipboard.writeText(urlGenerate);

		Swal.fire({
			title: "Link copiado!",
			html: urlGenerate,
			icon: "success",
			confirmButtonText: "Ok"
		});
	}, [user.company?._id]);

	const handleUsersQrExport = useCallback(() => {
		let { sorted, filtered } = table.current.state;

		let sort = sorted[0] || {};

		filtered = [...filtered, { id: "qrPrinted", value: true }];

		dispatch(
			actions.getUsers(
				0,
				10000,
				filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
				sort.id,
				sort.desc,
				true,
				(err, data) => {
					if (err) {
						console.log(err);
					} else {
						const dataSet = getExcelData(columns, data);

						if (dataSet)
							setExport(
								<ExcelFile
									filename={
										"Usuários impressos" +
										"_" +
										dayjs().format("YYYY-MM-DD-kk-mm-ss")
									}
									hideElement
								>
									<ExcelSheet dataSet={dataSet} name="Usuários impressos" />
								</ExcelFile>
							);

						setTimeout(() => {
							setExport(null);
						}, 1000);
					}
				}
			)
		);
	}, [table.current, table.current?.state?.pages, columns]);

	const handleGenerateQrCode = async data => {
		await generateUserTag(data);

		dispatch(
			actions.editUser(data._id, {
				...data,
				qrPrinted: true,
				checkInAt: new Date()
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title="Usuários"
						tableRef={table}
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						onRowClicked={
							["admin"].includes(user.role?.toLowerCase())
								? row => history.push(`/user/` + row._id)
								: undefined
						}
						headerRightComponent={
							isAdmin ? (
								<>
									<ButtonToolbar>
										<ButtonGroup className="btn-group--icons" dir="ltr">
											<input
												type="file"
												accept=".xls,.xlsx"
												style={{ display: "none" }}
												onChange={e =>
													handleCheckInExcel(e.target.files[0], "users")
												}
												ref={checkInInputRef}
											/>

											{state.loading?.import ? (
												<Spinner color="primary" size={16} />
											) : null}
											<Button
												id="users-checkin"
												disabled={state.loading?.import}
												onClick={handleCheckInInputClick}
											>
												<AiOutlineCheck />
											</Button>
											<input
												type="file"
												accept=".xls,.xlsx"
												style={{ display: "none" }}
												onChange={e =>
													handleImportExcel(e.target.files[0], "users")
												}
												ref={fileInputRef}
											/>
											{state.loading?.import ? (
												<Spinner color="primary" size={16} />
											) : null}
											<Button
												id="users-import"
												disabled={state.loading?.import}
												onClick={handleFileInputClick}
											>
												<AiOutlineImport />
											</Button>
											{/* <Button
												color="primary"
												id="users-prize"
												onClick={handlePrizeDraw}
												size="md"
											>
												<FaAward />
											</Button> */}

											<Button
												color="primary"
												id="users-qr-export"
												onClick={handleUsersQrExport}
												size="md"
											>
												<FaQrcode />
											</Button>
											{/* <TooltipComponent
												title="Iniciar sorteio"
												target="users-prize"
											/> */}
											<TooltipComponent
												title="Exportar usuários com qr code impresso"
												target="users-qr-export"
											/>
										</ButtonGroup>
									</ButtonToolbar>
								</>
							) : isManager || isBuilder ? (
								<>
									<Button
										color="primary"
										id="generate-link"
										onClick={handleGenerateLink}
										size="md"
									>
										<FiLink />
									</Button>
									<TooltipComponent
										title="Gerar link de cadastro"
										target="generate-link"
									/>
								</>
							) : null
						}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={columns}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />
			{exportComponent}
		</Container>
	);
}

export default Report;
