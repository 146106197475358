import React, { useState, useRef, useEffect } from "react";
import { reduxForm, formValueSelector, reset } from "redux-form";
import validate from "./validate";
import FormOne from "./PageFormOne";
import FormTwo from "./PageFormTwo";
import FormThree from "./PageFormThree";
import { useSelector } from "react-redux";

const successMessageStyle = {
	padding: "20px",
	backgroundColor: "#d4edda",
	color: "#155724",
	marginTop: "20px",
	borderRadius: "5px",
	textAlign: "center"
};

const Create = ({ onSubmit, reset, change }) => {
	const [page, setPage] = useState(1);
	const [formData, setFormData] = useState({});

	const state = useSelector(state => state);

	const selector = formValueSelector("expositor_form");

	const done = selector(state, "done");
	const type = selector(state, "type");

	const onFormOneSubmit = () => {
		setFormData(prevData => ({ ...prevData }));
		setPage(2);
	};

	const onFormTwoSubmit = data => {
		// setFormData(prevData => ({ ...prevData, ...data }));
		// setPage(3);
		onSubmit(data); // Submeter todos os dados acumulados para o backend
	};

	const onFormThreeSubmit = data => {
		const finalData = { ...formData, ...data };
		onSubmit(finalData); // Submeter todos os dados acumulados para o backend
	};

	const previousPage = () => {
		setPage(page => Math.max(page - 1, 1));
	};

	const handleReset = () => {
		reset();
		setFormData({});
		setPage(1);
	};

	if (done) {
		return (
			<form className="form">
				<div className="form__form-group" style={successMessageStyle}>
					<p>Credenciamento efetuado com sucesso!</p>

					<p>
						Esperamos você no 1º Fórum do Setor de Empresas de Energia Elétrica,
						que acontecerá nos dias 25 e 26 de março de 2025, em Florianópolis,
						no Passeio Sapiens
					</p>
				</div>
				{/* <div className="account__btn__group">
					<button
						type="button"
						className="btn btn-primary account__btn account__btn--small"
						onClick={handleLogin}
					>
						Entrar
					</button>
				</div> */}
				<div className="account__btn__group">
					<button
						type="button"
						className="btn btn-secondary account__btn account__btn--small"
						onClick={handleReset}
					>
						Realizar novo cadastro
					</button>
				</div>
			</form>
		);
	}

	return (
		<div>
			{page === 1 && <FormOne handleSubmit={onFormOneSubmit} />}
			{page === 2 && (
				<FormTwo
					onSubmit={onFormTwoSubmit}
					previousPage={previousPage}
					type={type}
				/>
			)}
			{page === 3 && (
				<FormThree
					onSubmit={onFormThreeSubmit}
					previousPage={previousPage}
					type={type}
				/>
			)}
		</div>
	);
};

export default reduxForm({
	form: "expositor_form", // Nome do formulário no Redux Form
	validate
})(Create);
