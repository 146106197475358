import React, { useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../ReportAttendeeEvent/actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import { useHistory } from "react-router";
import { useMemo } from "react";
import eventAttendeeStatus from "../../../../../models/eventAttendeeStatus";
import { CPF } from "components/NumberFormat";
import { isInRole } from "helpers/auth";
import userRole from "models/userRole";
import {
	MdDelete,
	MdOutlineChecklist,
	MdOutlineChecklistRtl
} from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import Add from "./Add";
import Delete from "./Delete";
import TooltipComponent from "components/TooltipComponent";
import * as XLSX from "xlsx";

let fetchDataDebounced = null;

function Report({ id, title, event }) {
	const dispatch = useDispatch();
	const table = useRef(null);
	const history = useHistory();

	const fileInputRef = useRef(null);

	const state = useSelector(s => s.attendeeEvent);
	const { user } = useSelector(s => s.login);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		filtered = [...filtered];

		filtered.push({ id: "event", value: id });

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getAttendeeEvent(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	// const handleEditClick = item => {
	// 	dispatch(
	// 		actions.setValue({
	// 			showEditForm: true,
	// 			item
	// 		})
	// 	);
	// };

	const addNewItem = () => {
		dispatch(
			actions.setValue({
				showAddForm: true
			})
		);
	};

	const handleDeleteClick = item => {
		dispatch(
			actions.setValue({
				showDeleteForm: item
			})
		);
	};

	// const handleShowModal = () => {
	// 	setShowModal(true);
	// };

	const handleFileInputClick = type => {
		fileInputRef.current.id = type;
		fileInputRef.current.click();
	};

	const handleImportExcel = (file, type) => {
		if (!file) return;

		const reader = new FileReader();
		reader.onload = async e => {
			const data = new Uint8Array(e.target.result);

			fileInputRef.current.value = ""; // Limpar o input

			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

			// Extract all values from column A
			const columnAValues = jsonData
				.map(row => row[0])
				.filter(value => value !== undefined);

			console.log(columnAValues);
			// Processar os dados para extrair as informações desejadas
			const processedData = columnAValues
				.map(userId => ({
					userId,
					date: Number(type) === 1 ? event.when : event.until
				}))
				.filter(c => c.userId?.length === 10);

			console.log(processedData);

			dispatch(
				actions.importCheckInOut(id, type, processedData, err => {
					if (err) {
						console.log(err);
						alert(err);
					} else {
						alert("Importação realizada com sucesso!");
					}
				})
			);
		};

		reader.readAsArrayBuffer(file);
	};

	const isAdmin = useMemo(() => {
		return isInRole(user, [userRole.ADMIN]);
	}, [user]);

	const columns = useMemo(() => {
		return [
			// {
			// 	Header: "Data criação",
			// 	id: "createdAt",
			// 	accessor: c =>
			// 		c.createdAt ? dayjs(c.createdAt).format(ls.dateTimeFormat) : "",
			// 	width: 210
			// },
			{
				Header: "Usuário",
				id: "user",
				accessor: c => c.user?.fullName
			},
			{
				Header: "E-mail",
				id: "email",
				accessor: c => c.user?.email || "Não informado"
			},
			{
				Header: "Telefone",
				id: "phone",
				accessor: c => c.user?.nationalPhoneNumber || "Não informado"
			},
			{
				Header: "Empresa",
				id: "company",
				accessor: c => c.user?.companyName || "Não informado"
			},
			{
				Header: "Cargo",
				id: "profession",
				accessor: c => c.user?.profession || "Não informado"
			},
			{
				Header: "Cidade",
				id: "city",
				accessor: c => c.user?.address?.city || "Não informado"
			},
			{
				Header: "Estado",
				id: "state",
				accessor: c => c.user?.address?.state || "Não informado"
			},
			// {
			// 	Header: "Check in",
			// 	id: "checkInDate",
			// 	filterable: false,
			// 	accessor: c =>
			// 		c.checkIns?.[0]
			// 			? dayjs(c.checkIns[0].createdAt).format(ls.dateTimeFormat)
			// 			: "Não definido",
			// 	width: 150
			// },
			// {
			// 	Header: "Check out",
			// 	id: "checkOutDate",
			// 	filterable: false,
			// 	accessor: c =>
			// 		c.checkOuts?.[0]
			// 			? dayjs(c.checkOuts[c.checkOuts.length - 1].createdAt).format(
			// 					ls.dateTimeFormat
			// 			  )
			// 			: "Não definido",
			// 	width: 150
			// },
			// {
			// 	Header: "Status",
			// 	id: "status",
			// 	accessor: c => ls[eventAttendeeStatus[c.status]],
			// 	width: 100,
			// 	Filter: ({ filter, onChange }) => (
			// 		<select
			// 			onChange={event => onChange(event.target.value)}
			// 			style={{ width: "100%" }}
			// 			value={filter ? filter.value : "all"}
			// 		>
			// 			<option value="">Todos</option>
			// 			{Object.keys(eventAttendeeStatus).map(c => (
			// 				<option key={c} value={c}>
			// 					{ls[eventAttendeeStatus[c]]}
			// 				</option>
			// 			))}
			// 		</select>
			// 	)
			// },
			{
				Header: "Ações",
				width: 80,
				filterable: false,
				id: "actions",
				Cell: props => {
					return (
						<ButtonToolbar>
							<ButtonGroup className="btn-group--icons" dir="ltr">
								{/* <Button
									color="primary"
									onClick={() => handleEditClick(props.original)}
								>
									<MdEdit style={{ margin: 0 }} />
								</Button> */}

								<Button
									color="cancel"
									onClick={() => handleDeleteClick(props.original)}
								>
									<MdDelete style={{ margin: 0 }} />
								</Button>
							</ButtonGroup>
						</ButtonToolbar>
					);
				}
			}
		];
	}, [isAdmin]);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Participantes"
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						headerRightComponent={
							isAdmin ? (
								<>
									{state.loading?.import ? (
										<Spinner color="primary" size={16} />
									) : null}
									<Button
										style={{
											marginLeft: "auto"
										}}
										id="add-new-item"
										color="primary"
										onClick={addNewItem}
									>
										<FaPlus />
									</Button>
									<TooltipComponent
										target="add-new-item"
										title="Adicionar novo item"
									/>
									<input
										type="file"
										accept=".xls,.xlsx"
										style={{ display: "none" }}
										onChange={e =>
											handleImportExcel(e.target.files[0], e.target.id)
										}
										ref={fileInputRef}
									/>
									<Button
										id="checkin-import"
										disabled={state.loading?.import}
										onClick={() => handleFileInputClick(1)}
									>
										<MdOutlineChecklist />
									</Button>
									<TooltipComponent
										target="checkin-import"
										title="Importar check-ins"
									/>
									<Button
										id="checkout-import"
										disabled={state.loading?.import}
										onClick={() => handleFileInputClick(2)}
									>
										<MdOutlineChecklistRtl />
									</Button>
									<TooltipComponent
										target="checkout-import"
										title="Importar check-outs"
									/>
								</>
							) : null
						}
						onRowClicked={row => history.push(`/user/` + row._id)}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={columns}
					/>
				</Col>
			</Row>
			<Add load={load} event={id} />
			<Delete load={load} />
			{/*<Edit load={load} /> */}
		</Container>
	);
}

export default Report;
