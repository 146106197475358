import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, ButtonToolbar, Container, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import renderSelectField from "shared/components/form/Select";

const AddAttendeeForm = ({
	handleSubmit,
	closeModal,
	initialValues,
	usersOptions,
	onChange
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Usuário</span>
						<div className="form__form-group-field">
							<Field
								name="user"
								component={renderSelectField}
								type="text"
								options={usersOptions}
								placeholder="Selecione um usuário"
								defaultValue={initialValues.user}
								onChange={onChange}
								onInputChange={userSearch => onChange({ userSearch })}
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit">
					Adicionar
				</Button>
				<Button type="button" color="secondary" onClick={closeModal}>
					Cancelar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "event_attendee_add"
})(AddAttendeeForm);

AddAttendeeForm.propTypes = {
	handleSubmit: PropTypes.func,
	closeModal: PropTypes.func
};
