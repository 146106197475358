import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
import AddForm from "../../../../components/AddAttendeeForm";
import { createEventAttendee, setValue } from "../actions";
import { getUsers } from "containers/User/containers/Report/actions";

let findUsersDebounced = null;

function Add({ load, event }) {
	const dispatch = useDispatch();

	const { showAddForm } = useSelector(s => s.attendeeEvent);
	const { items: users } = useSelector(s => s.users);

	const onSubmit = values => {
		dispatch(
			createEventAttendee({ event, user: values.user?.value }, err => {
				if (err) {
					console.log(err);
				} else {
					load && load();
					closeModal();
				}
			})
		);
	};

	const onChange = values => {
		if (values.user) {
			if (values.user.value === "add") {
				dispatch(setValue({ item: values }));
				history.push("/user/new");
			}
		}

		if (values.userSearch) {
			handleFindUser(values.userSearch);
		}
	};

	const handleFindUser = user => {
		if (findUsersDebounced) {
			findUsersDebounced.cancel();
		}

		findUsersDebounced = _.debounce(
			() =>
				dispatch(
					getUsers(
						0,
						10,
						`&filters[fullName]=${user}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar os usuários, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findUsersDebounced();
	};

	const closeModal = () => {
		dispatch(setValue({ showAddForm: false }));
	};

	const usersOptions = useMemo(() => {
		console.log(users);

		return [
			{ value: "add", label: "Adicionar nova" },
			...users.map(c => ({
				value: c._id,
				label: c.fullName
			}))
		];
	}, [users]);

	const initialValues = {};

	return (
		<Modal
			toggle={closeModal}
			isOpen={showAddForm}
			title="Adicionar participante"
		>
			<AddForm
				closeModal={closeModal}
				initialValues={initialValues}
				onSubmit={onSubmit}
				usersOptions={usersOptions}
				onChange={onChange}
			/>
		</Modal>
	);
}

export default Add;
