import validate from "containers/User/components/validate";
import ls from "Localization";
import userGenders from "models/userGenders";
import userRole from "models/userRole";
import userStatus from "models/userStatus";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmissionError } from "redux-form";

import Modal from "components/Modal";
import EditForm from "../../components/editForm";
import { setValue, editUser, clearValues } from "../Report/actions";
import _ from "lodash";
import { getCompanies } from "containers/Company/containers/Report/actions";
import { useHistory } from "react-router";
import { upload } from "redux/actions/upload";
import { generateUserTag } from "helpers/tag";
import { getUser } from "../Detail/actions";

let findCompaniesDebounced = null;

function Edit({ load }) {
	const dispatch = useDispatch();

	const history = useHistory();

	const { loading, item, showEditForm } = useSelector(s => s.users);

	const { items: companies } = useSelector(s => s.company);
	const { user } = useSelector(s => s.login);

	const onChange = values => {
		if (values.company) {
			if (values.company.value === "add") {
				history.push("/company/new");
			}
		}

		if (values.companySearch) {
			handleFindCompany(values.companySearch);
		}
	};

	useEffect(() => {
		dispatch(clearValues());
		handleFindCompany();
	}, [dispatch]);

	const handleFindCompany = company => {
		if (findCompaniesDebounced) {
			findCompaniesDebounced.cancel();
		}

		findCompaniesDebounced = _.debounce(
			() =>
				dispatch(
					getCompanies(
						0,
						10,
						`&filters[name]=${company}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar os expositores, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findCompaniesDebounced();
	};

	const isAdmin = useMemo(() => {
		if (!user) return false;

		return user.role.toLowerCase() === "admin";
	}, [user]);

	const isBuilder = useMemo(() => {
		if (!item) return false;

		if (item.company?.companyType === "M") {
			return true;
		}

		if (!user) return false;

		return user.role.toLowerCase() === "builder";
	}, [item, user]);

	const isReceptionist = useMemo(() => {
		if (!user) return false;

		return user.role.toLowerCase() === "receptionist";
	}, [user]);

	const companiesOptions = useMemo(() => {
		return [
			{ value: "add", label: "Adicionar nova" },
			...companies
				.filter(c => isAdmin || isReceptionist || c._id === user.company)
				.map(c => ({
					value: c._id,
					label: c.name
				}))
		];
	}, [isAdmin, isReceptionist, companies]);

	if (
		item?.company?._id &&
		!companiesOptions.find(c => c.value === item?.company._id)
	) {
		companiesOptions.push({
			value: item.company._id,
			label: item.company.name
		});
	}

	const roleOptions = useMemo(
		() =>
			Object.keys(userRole)
				.filter(
					c =>
						isAdmin || ![userRole.ADMIN, userRole.PROJECT_MANAGER].includes(c)
				)
				.map(c => ({
					value: c,
					label: ls[userRole[c]]
				})),
		[isAdmin]
	);

	if (!item) return null;

	const onSubmit = async values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		const fileUrls = [];

		if (values.professionalFile && values.professionalFile.length) {
			const filesArray = Array.from(values.professionalFile);
			const uploadPromises = filesArray.map(
				file =>
					new Promise((resolve, reject) => {
						dispatch(
							upload("user", file, (error, url) => {
								if (error) {
									console.error("Erro no upload:", error);
									alert("Erro no upload: " + error.message);
									reject(error);
								} else {
									console.log("Upload OK: ", url);
									fileUrls.push({ url });
									resolve(url);
								}
							})
						);
					})
			);

			try {
				await Promise.all(uploadPromises);
			} catch (error) {
				console.log("Erro durante o upload dos arquivos: ", error);
				return;
			}
		}

		const data = {
			...item,
			firstName: values.firstName,
			lastName: values.lastName,
			badgeName: values.badgeName,
			document: values.document && values.document.match(/\d/g).join(""),
			email: values.email,
			companyName: values.companyName,
			phoneNumber:
				values.phoneNumber && `+55${values.phoneNumber.match(/\d/g).join("")}`,
			birthDate: values.birthDate && values.birthDate,
			role: values.role.value,
			gender: values.gender && values.gender.value,
			status: values.status.value,
			company: values.company?.value || undefined,
			profession: values.profession?.value || values.profession || undefined,
			professionalFiles: [...(item.professionalFiles || []), ...fileUrls]
		};

		dispatch(
			editUser(item._id, data, err => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					if (isReceptionist) {
						dispatch(
							getUser(item._id, (err, model) => {
								if (err) {
									console.error(err);
								}

								generateUserTag(model).then();

								dispatch(editUser(model._id, { ...model, qrPrinted: true }));
							})
						);
					}

					closeModal();
					load && load();
				}
			})
		);
	};

	const statusOptions = Object.keys(userStatus).map(c => ({
		value: c,
		label: ls[userStatus[c]]
	}));

	const genderOptions = Object.keys(userGenders).map(c => ({
		value: c,
		label: ls[userGenders[c]]
	}));

	const closeModal = () => {
		dispatch(setValue({ showEditForm: false }));
	};

	const initialValues = {
		firstName: item.firstName,
		lastName: item.lastName,
		badgeName: item.badgeName,
		document: item.document,
		email: item.email,
		companyName: item.companyName,
		phoneNumber: item.nationalPhoneNumber,
		birthDate: item.birthDate,
		role: roleOptions.find(c => c.value === item.role) || {
			value: "USER",
			label: ls[userRole["USER"]]
		},
		gender: genderOptions.find(c => c.value === String(item.gender)),
		status: statusOptions.find(c => c.value === String(item.status)),
		profession: item.profession || "",
		company: companiesOptions.find(
			c => c.value === (item.company?._id || item.company)
		)
	};

	return (
		<Modal
			toggle={closeModal}
			isOpen={showEditForm}
			size="xl"
			title="Editar Usuário"
		>
			<EditForm
				closeModal={closeModal}
				initialValues={initialValues}
				onSubmit={onSubmit}
				onChange={onChange}
				roleOptions={roleOptions}
				statusOptions={statusOptions}
				genderOptions={genderOptions}
				companiesOptions={companiesOptions}
				loading={loading}
				isAdmin={isAdmin}
				isBuilder={isBuilder}
				isReceptionist={isReceptionist}
			/>
		</Modal>
	);
}

export default Edit;
