import React from "react";
import { Field, reduxForm } from "redux-form";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import validate from "../validate";
import { CPF, DATE } from "components/NumberFormat";

const emissionForm = ({ handleSubmit }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<div className="form__form-group">
				<span className="form__form-group-label">CPF</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<AccountOutlineIcon />
					</div>
					<Field
						name="document"
						component={CPF}
						type="text"
						placeholder="Seu CPF"
					/>
				</div>
			</div>
			<div className="form__form-group">
				<span className="form__form-group-label">Data de nascimento</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<AccountOutlineIcon />
					</div>
					<Field
						name="birthDate"
						component={DATE}
						type="text"
						placeholder="Sua data de nascimento"
					/>
				</div>
			</div>

			<button
				className="btn btn-primary account__btn account__btn--small"
				type="submit"
			>
				Emitir certificado
			</button>
		</form>
	);
};

export default reduxForm({
	form: "certificate_emission",
	validate
})(emissionForm);
