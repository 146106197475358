import QRCode from "qrcode";
import { toPng } from "html-to-image";

export async function generateUserTag(data, close) {
	const response = await QRCode.toDataURL(data._id, {
		width: 350,
		margin: 2
	});

	let child = document.createRange().createContextualFragment(
		`<div
					id="qr"
					style="
				height: 400px;
				width: 900px;
				padding: 10px 10px;
				background-color: rgb(255, 255, 255);
				display: flex;
					align-items: center;
				"
				>
					<div
						id="QRCode"
						style="display: flex; justify-content: center; align-items: center; height: 100%; padding: 0 0 0 80px;"
					>
						<img
							id="qr-img"
							style="height: 200px; width: 200px"
							src="${response}"
						/>
					</div>
					<div
						id="Info"
						style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%;"
					>
						<span
							style="
					  line-height: 100%;
					  font-size: ${(() => {
							let name = String(data.badgeName || data.fullName || "");
							if (name.length < 20) return "3.5";
							return "3.25";
						})()}rem;
						width: 100%;
					  font-weight: 500;
					  white-space: nowrap;
					  overflow: hidden;
					  text-overflow: ellipsis;
						text-align: center;
					"
						>
						${(() => {
							let name = String(data.badgeName || data.fullName || "");
							const len = name.length;

							if (len > 20) {
								const names = name.split(" ");

								const first = names.shift();
								const last = names.pop();

								const meddle = names
									.map(c => c[0])
									.join(" ")
									.toUpperCase();

								name = `${first} ${meddle} ${last}`;
							}

							return name;
						})()}
						</span>
						<span
							style="
					  line-height: 100%;
							text-align: center;
					  font-size: ${(() => {
							let name = String(
								data.company?.socialName ||
									data.company?.name ||
									data.companyName ||
									data.ticketType ||
									""
							);

							if (name === "undefined") return "3.5";

							if (name.length <= 10) return "3.5";
							if (name.length <= 15) return "3.25";
							if (name.length <= 20) return "3";
							if (name.length <= 30) return "2.75";
							return "2.5";
						})()}rem;
						  font-weight: 500;
						  overflow: hidden;
						  text-overflow: ellipsis;
								width: 100%;
					"
						>

				  ${(() => {
						let name = String(
							data.company?.socialName ||
								data.company?.name ||
								data.companyName ||
								data.ticketType ||
								""
						);

						if (name === "undefined") return "";

						return name;
					})()}
						</span>
						<span
							style="
					  line-height: 100%;
							text-align: center;
					  font-size: ${(() => {
							let name = String(data.profession || "");

							if (name === "undefined") return "3.5";

							if (name.length <= 10) return "3.5";
							if (name.length <= 15) return "3.25";
							if (name.length <= 20) return "3";
							if (name.length <= 30) return "2.75";
							return "2.5";
						})()}rem;
						  font-weight: 500;
						  overflow: hidden;
						  text-overflow: ellipsis;
								width: 100%;
					"
						>

				  ${(() => {
						let name = String(data.profession || "");

						if (name === "undefined") return "";

						return name;
					})()}
						</span>
						<span
							style="
					  	line-height: 100%;
						text-align: center;
						font-size: ${(() => {
							if (!data.address) return "2.5";

							let name =
								data.address.city && data.address.state
									? `${data.address.city || ""} - ${data.address.state || ""}`
									: data.address.country || "";

							if (name.length <= 10) return "3";
							if (name.length <= 15) return "2.75";
							if (name.length <= 20) return "2.5";
							if (name.length <= 30) return "2.25";
							return "2";
						})()}rem;
						  font-weight: 500;
						  overflow: hidden;
						  text-overflow: ellipsis;
								width: 100%;
					"
						>

				  ${(() => {
						if (!data.address) return "";

						let name =
							data.address.city && data.address.state
								? `${data.address.city || ""} - ${data.address.state || ""}`
								: data.address.country || "";

						return name.toUpperCase();
					})()}
						</span>
					</div>
				</div>`
	);

	document.body.appendChild(child);

	document.querySelector("#qr-img").onload = () => {
		setTimeout(() => {
			toPng(document.querySelector("#qr"), {}).then(dataUrl => {
				const doc = new window.jspdf.jsPDF("l", "mm", [90, 40]);

				doc.addImage(dataUrl, "png", 0, 0, 90, 40);

				doc.autoPrint();

				const pdfURL = doc.output("bloburl");

				document.body.removeChild(document.querySelector("#qr"));

				const c = window.open(pdfURL, "_blank");

				if (close) {
					setTimeout(() => {
						console.log("Closing PDF URL: ", close);
						c.close();
					}, 4000);
				}
			});
		}, 1000);
	};
}
