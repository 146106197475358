const strings = {
	appName: "Acelere Brasil",
	client: "Cliente",
	admin: "Administrador",
	Pending: "Pendente",
	Enabled: "Ativo",
	Disabled: "Desativado",
	Paid: "Pago",
	Canceled: "Cancelado",
	"you need to reset your password":
		"Senha expirada, clique em recuperar senha",
	newPasswordSentForEmail: "Nova senha enviada para o e-mail informado",
	"User registration is pending":
		"Seu cadastro está pendente de aprovação, iremos verificar seu cadastro em breve, você será informado por e-mail.",
	"User is disabled": "Acesso desativado pelo administrador do sistema",
	role: "Permissão",
	"Email already exists": "Email já registrado",
	ADMIN: "Administrador",
	MANAGER: "Gerente",
	Receptionist: "Recepcionista",
	USER: "Usuário",
	Admin: "Administrador",
	Manager: "Gerente",
	User: "Usuário",
	Male: "Masculino",
	Female: "Feminino",
	Other: "Outro",
	dateTimeFormatWithoutSeconds: "DD/MM/YYYY HH:mm",
	dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
	dateFormat: "DD/MM/YYYY",
	Absolute: "Absoluto",
	Percent: "Porcentagem",
	Available: "Disponível",
	Used: "Usado",
	car: "Carro",
	motorcycle: "Moto",
	trip: "Passeio",
	brand: "Marca",
	canvas: "Lona",
	category: "Categoria",
	code: "Código",
	price: "Preço",
	customsCode: "Código alfandegario",
	description: "Descrição",
	imported: "Importado",
	indice: "Indice",
	inmetroNumber: "Nº Inmetro",
	manufacturer: "Fabricante",
	modelProduct: "Modelo",
	name: "Nome",
	origin: "Origem",
	size: "Tamanho",
	specification: "Especificação",
	utility: "Utilidade",
	Done: "Finalizado",
	Close: "Encerrado",
	Raw: "Novo",
	Assigned: "Atribuído",
	Working: "Trabalhando",
	Qualified: "Qualificado",
	Nurture: "Nutrir",
	Unqualified: "Não qualificado",
	BankBillet: "Boleto",
	CreditCard: "Cartão de crédito",
	App: "Aplicativo",
	Sales: "Vendas",
	Pix: "Pix",
	Going: "Inscrito",
	Went: "Participou",
	NotGo: "Não participou",
	Confirmed: "Confirmado",
	dateFormatShort: "DD/MM/YYYY",
	WaitingPayment: "Aguardando pagamento",
	Function: "Função",
	ErrorEmailPassword: "E-mail ou senha inválidos",
	yes: "Sim",
	no: "Não",
	TodaysLeads: "Leads de hoje",
	Last7DaysLeads: "Leads dos últimos 7 dias",
	Last30DaysLeads: "Leads dos últimos 30 dias",
	TotalLeads: "Total de Leads",
	TotalUsers: "Total de usuários",
	TotalExpositors: "Total de expositores",
	TotalCheckIns: "Total de check-ins",
	TodaysCheckIns: "Check-ins de hoje",
	Last7DaysCheckIns: "Check-ins dos últimos 7 dias",
	YesterdayCheckIns: "Check-ins de ontem",
	BUILDER: "Montadora",
	Builder: "Montadora",
	Approved: "Aprovado",
	Rejected: "Rejeitado",
	InAnalysis: "Em análise",
	Project_Manager: "Gerente de projeto",
	Lecture: "Palestra",
	Course: "Curso",
	Training: "Treinamento",
	Workshop: "Workshop",
	RoundTable: "Mesa-redonda",
	Symposium: "Simpósio",
	Seminar: "Seminário",
	Congress: "Congresso",
	Fair: "Feira",
	Exhibition: "Exposição",
	Vernissage: "Vernissage",
	Festival: "Festival",
	Show: "Show",
	Others: "Outros",
	in: "Entrada",
	out: "Saída"
};

export default strings;
