/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import ReportTable from "components/Report";
import Delete from "../Delete";
import Edit from "../Edit";
import React from "react";
import ls from "Localization";
import dayjs from "dayjs";

let fetchDataDebounced = null;

function Report({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);
	const state = useSelector(s => s.event);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getEvent(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	// eslint-disable-next-line no-unused-vars
	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handleDeleteClick = data => {
		dispatch(
			actions.setValue({
				showDeleteForm: data
			})
		);
	};

	const handleEditClick = data => {
		dispatch(
			actions.setValue({
				showEditForm: true,
				item: data
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title="Eventos"
						tableRef={table}
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						onRowClicked={row => history.push(`/event/` + row._id)}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Titulo",
								accessor: "title",
								width: 150
							},
							{
								Header: "Descrição",
								accessor: "description"
							},
							{
								Header: "Quando",
								id: "when",
								accessor: c =>
									c.when
										? dayjs(c.when).format(ls.dateTimeFormat)
										: "Não definido",
								width: 150
							},

							{
								Header: "Ações",
								width: 80,
								filterable: false,
								id: "actions",
								Cell: props => {
									return (
										<ButtonToolbar>
											<ButtonGroup className="btn-group--icons" dir="ltr">
												<Button
													color="primary"
													onClick={() => handleEditClick(props.original)}
												>
													<MdEdit style={{ margin: 0 }} />
												</Button>

												<Button
													color="cancel"
													onClick={() => handleDeleteClick(props.original)}
												>
													<MdDelete style={{ margin: 0 }} />
												</Button>
											</ButtonGroup>
										</ButtonToolbar>
									);
								}
							}
						]}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />
		</Container>
	);
}

export default Report;
