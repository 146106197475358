import React, { useEffect, useState } from "react";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import { SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { submitRecoverPassword } from "redux/actions/login";
import Alert from "shared/components/Alert";
import actions from "redux/actions";
import ls from "Localization";

const ForgotPassword = ({ history }) => {
	const dispatch = useDispatch();
	const state = useSelector(s => s.login);
	const [message, setMessage] = useState("");

	useEffect(() => {
		setErrors();
	}, [state]);

	const setErrors = () => {
		const { errors, message } = state;
		setMessage(errors.recoverEmail || message);
	};

	const handleDismiss = () => {
		const { errors, message } = state;
		errors !== "" && dispatch(actions.login.setValue({ errors: "" }));
		message !== "" && dispatch(actions.login.setValue({ message: "" }));
		dispatch(actions.login.setValue({ username: "" }));
	};

	const onSubmit = data => {
		dispatch(
			submitRecoverPassword(data.email, err => {
				if (err) {
					setMessage(err);
					throw new SubmissionError(err);
				} else {
					setMessage(data.email);
					history.push("/Login");
				}
			})
		);
	};

	return (
		<>
			{message && (
				<div
					style={{
						position: "absolute",
						right: 16,
						top: 16
					}}
				>
					<Alert
						color="warning"
						className="alert--bordered"
						icon
						onDismiss={handleDismiss}
					>
						<p>
							<span className="bold-text">{message}</span>
						</p>
					</Alert>
				</div>
			)}
			<div className="account">
				<div className="account__wrapper">
					<div className="account__card">
						<div className="account__head">
							<div className="account__logo" />
							{/* <div className="flex-center">
								<h3 className="account__title">{ls.appName}</h3>

								<h4 className="account__subhead subhead">
									Painel administrativo
								</h4>
							</div> */}
						</div>
						<ForgotPasswordForm onSubmit={onSubmit} />
					</div>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
