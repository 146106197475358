import userRole from "models/userRole";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
//import { logoutUser } from "redux/actions/login";
import { isInRole, isLogged } from "../../helpers/auth";
import BudgetsRoutes from "../Budgets/routes";
import BuilderRoutes from "../Builder/routes";
import CashbackRoutes from "../Cashback/routes";
import ChangePassword from "../ChangePassword";
import CompanyRoutes from "../Company/routes";
import ConstructionRoutes from "../Construction/routes";
import CoursesRoutes from "../Courses/routes";
import Dashboard from "../Dashboard";
import DetailsLead from "../Lead/containers/Detail";
import EventsRoutes from "../Events/routes";
import ForgotPassword from "../ForgotPassword";
import Layout from "../Layout";
import Lead from "../Lead/containers/Report";
import LeadKanban from "../Lead/containers/Kanban";
import LogIn from "../LogIn";
import MainWrapper from "./MainWrapper";
import NewsRoutes from "../News/routes";
import ProductCategoriesRoutes from "../ProductCategory/routes";
import ProductsRoutes from "../Product/routes";
import Register from "../Register/routes";
import RegisterForCompany from "containers/Register/[id]";
import SelfService from "containers/SelfService";
import ServiceProviderRoutes from "../ServiceProvider/routes";
import ShowVideosRoutes from "../Courses/containers/ShowVideos/routes";
import TransactionRoutes from "../Transaction/routes";
import UserRoutes from "../User/routes";
import ConsumptionItemRoutes from "../Consumption/routes";
import CertificateRoutes from "../Certificate/routes";

import Emission from "../Certificate/containers/Emission";

const AuthRoute = ({ user, needRoles, ...rest }) => {
	if (isInRole(user, needRoles)) return <Route {...rest} />;

	return null;
};

const wrappedRoutes = () => {
	const { user } = useSelector(s => s.login);
	//const dispatch = useDispatch();

	// if (user.role === "BIDDER") {
	// 	dispatch(logoutUser());
	// 	alert("Acesso negado");
	// 	return <Redirect to="/Login" />;
	// }

	const isBuilder = useMemo(() => {
		if (!user) return false;

		return isInRole(user, [userRole.BUILDER]);
	}, [user]);

	return (
		<div>
			<Layout />
			<div className="container__wrap">
				<Switch>
					<AuthRoute
						path="/"
						exact
						component={isBuilder ? CompanyRoutes : Dashboard}
						user={user}
						needRoles={[
							userRole.ADMIN,
							userRole.MANAGER,
							userRole.USER,
							userRole.BUILDER
						]}
					/>
					<Route
						exact
						path="/Cadastro/:type/:id"
						component={RegisterForCompany}
					/>
					<Route exact path="/Cadastro/:id" component={RegisterForCompany} />
					<AuthRoute
						path="/lead"
						exact
						component={Lead}
						user={user}
						needRoles={[userRole.ADMIN, userRole.MANAGER, userRole.USER]}
					/>
					<AuthRoute
						path="/lead/funil"
						exact
						component={LeadKanban}
						user={user}
						needRoles={[userRole.ADMIN, userRole.MANAGER, userRole.USER]}
					/>
					<AuthRoute
						path="/lead/:id"
						exact
						component={DetailsLead}
						user={user}
						needRoles={[userRole.ADMIN, userRole.MANAGER, userRole.USER]}
					/>
					<AuthRoute
						path="/user"
						component={UserRoutes}
						user={user}
						needRoles={[
							userRole.ADMIN,
							userRole.MANAGER,
							userRole.RECEPTIONIST,
							userRole.BUILDER
						]}
					/>
					<AuthRoute
						path="/productCategory"
						component={ProductCategoriesRoutes}
						user={user}
						needRoles={[userRole.ADMIN]}
					/>
					<AuthRoute
						path="/product"
						component={ProductsRoutes}
						user={user}
						needRoles={[userRole.ADMIN]}
					/>
					<AuthRoute
						path="/company"
						component={CompanyRoutes}
						user={user}
						needRoles={[
							userRole.ADMIN,
							userRole.MANAGER,
							userRole.BUILDER,
							userRole.PROJECT_MANAGER
						]}
					/>
					<AuthRoute
						path="/builder"
						component={BuilderRoutes}
						user={user}
						needRoles={[userRole.ADMIN, userRole.MANAGER]}
					/>
					<AuthRoute
						path="/serviceProvider"
						component={ServiceProviderRoutes}
						user={user}
						needRoles={[userRole.ADMIN, userRole.MANAGER]}
					/>
					<AuthRoute
						path="/news"
						component={NewsRoutes}
						user={user}
						needRoles={[userRole.ADMIN]}
					/>
					<AuthRoute
						path="/event"
						component={EventsRoutes}
						user={user}
						needRoles={[userRole.ADMIN]}
					/>

					<AuthRoute
						path="/certificate"
						component={CertificateRoutes}
						user={user}
						needRoles={[userRole.ADMIN]}
					/>

					<AuthRoute
						path="/budget"
						component={BudgetsRoutes}
						user={user}
						needRoles={[userRole.ADMIN, userRole.MANAGER, userRole.USER]}
					/>

					<AuthRoute
						path="/transaction"
						component={TransactionRoutes}
						user={user}
						needRoles={[userRole.ADMIN, userRole.MANAGER, userRole.USER]}
					/>

					<AuthRoute
						path="/cashback"
						component={CashbackRoutes}
						user={user}
						needRoles={[userRole.ADMIN, userRole.MANAGER]}
					/>

					<AuthRoute
						path="/construction"
						component={ConstructionRoutes}
						user={user}
						needRoles={[userRole.ADMIN, userRole.MANAGER]}
					/>
					<AuthRoute
						path="/courses"
						component={CoursesRoutes}
						user={user}
						needRoles={[userRole.ADMIN]}
					/>
					<AuthRoute
						path="/cursos/user"
						component={ShowVideosRoutes}
						user={user}
						needRoles={[userRole.MANAGER]}
					/>
					<AuthRoute
						path="/consumption/item"
						component={ConsumptionItemRoutes}
						user={user}
						needRoles={[userRole.ADMIN]}
					/>
				</Switch>

				<Route exact path="/ChangePassword" component={ChangePassword}></Route>
			</div>
		</div>
	);
};

const Router = () => {
	const login = useSelector(s => s.login);

	if (!login) return null;

	const loggedIn = isLogged(login);

	if (!loggedIn) {
		return (
			<MainWrapper>
				<main>
					<Switch>
						<Route exact path="/Login" component={LogIn} />
						<Route exact path="/Register" component={Register} />
						<Route exact path="/ForgotPassword" component={ForgotPassword} />
						<Route exact path="/SelfService" component={SelfService} />

						<Route path="/certificado/emissao" component={Emission} />
						<Route exact path="/Check/:type/:event" component={SelfService} />
						<Route
							exact
							path="/Cadastro/:type/:id"
							component={RegisterForCompany}
						/>
						<Route exact path="/Cadastro/:id" component={RegisterForCompany} />
						<Redirect to="/Login" />
					</Switch>
				</main>
			</MainWrapper>
		);
	}

	return (
		<MainWrapper>
			<main>
				<Switch>
					<Route exact path="/SelfService" component={SelfService} />

					<Route path="/certificado/emissao" component={Emission} />
					<Route exact path="/Check/:type/:event" component={SelfService} />
					<Route path="/" component={wrappedRoutes} />
					<Redirect to="/" />
				</Switch>
			</main>
		</MainWrapper>
	);
};

export default Router;
