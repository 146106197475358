import React, { useRef } from "react";
import OptionsPage from "./OptionsPage";
import EmailPage from "./EmailPage";
import DocumentPage from "./DocumentPage";
import QrCodePage from "./QrCodePage";
import ReaderPage from "./ReaderPage";

const SelfServiceForm = ({ onSubmit, page, setPage, type }) => {
	const form = useRef(null);

	const previousPage = () => {
		setPage(1);
	};

	return (
		<div>
			{page === 1 && <OptionsPage handleSubmit={setPage} type={type} />}
			{page === "email" && (
				<EmailPage ref={form} previousPage={previousPage} onSubmit={onSubmit} />
			)}
			{page === "document" && (
				<DocumentPage
					ref={form}
					previousPage={previousPage}
					onSubmit={onSubmit}
				/>
			)}
			{page === "qrCode" && (
				<QrCodePage onSubmit={onSubmit} previousPage={previousPage} />
			)}
			{page === "reader" && (
				<ReaderPage
					ref={form}
					previousPage={previousPage}
					onSubmit={onSubmit}
				/>
			)}
		</div>
	);
};

export default SelfServiceForm;
