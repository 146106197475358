import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { reset } from "redux-form";
import { issueCertificate } from "./actions";
import Alert from "shared/components/Alert";
import ls from "Localization";
import EmissionForm from "../../components/EmissionForm";

function Emission({ history }) {
	const dispatch = useDispatch();

	const [message, setMessage] = useState("");

	// const { loading } = useSelector(s => s.eventCreate);

	const handleDismiss = () => {
		setMessage("");
	};

	const onSubmit = async values => {
		const data = {
			document: values.document.match(/\d/g).join(""),
			birthDate: values.birthDate
		};

		dispatch(
			issueCertificate(data, (err, event) => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					dispatch(reset("event_create"));
					history.push("/event/" + event._id);
				}
			})
		);
	};

	return (
		<>
			{message && (
				<div
					style={{
						position: "absolute",
						right: 16,
						top: 16
					}}
				>
					<Alert
						color="warning"
						className="alert--bordered"
						icon
						onDismiss={handleDismiss}
					>
						<p>
							<span className="bold-text">{message}</span>
						</p>
					</Alert>
				</div>
			)}
			<div className="account">
				<div className="account__wrapper">
					<div className="account__card">
						<div className="account__head">
							{/* <div className="account__logo" /> */}
							<div className="flex-center">
								<h3 className="account__title">{ls.appName}</h3>
							</div>
						</div>
						<EmissionForm onSubmit={onSubmit} />
					</div>
				</div>
			</div>
		</>
	);
}
export default Emission;
