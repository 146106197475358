import * as api from "../../../../../services/api";
import { getErrors } from "../../../../../helpers/error";
import _ from "lodash";

export const GET_ATTENDEE_EVENTS = "[ATTENDEE EVENT] GET_ATTENDEE_EVENTS";
export const GET_ATTENDEE_ERROR = "[ATTENDEE EVENT] GET_ATTENDEE_ERROR";
export const GET_ATTENDEE_SUCCESS = "[ATTENDEE EVENT] GET_ATTENDEE_SUCCESS";

export const SET_VALUE = "[ATTENDEE EVENT] SET_VALUE";
export const CLEAR_VALUES = "[ATTENDEE EVENT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getAttendeeEvent(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ATTENDEE_EVENTS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/event/attendee?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_ATTENDEE_SUCCESS,
						payload: result.model
					});
				}

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_ATTENDEE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_ATTENDEE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const CREATE_EVENT_ATTENDEE = "[EVENTS CREATE] CREATE_EVENT_ATTENDEE";
export const CREATE_EVENT_ATTENDEE_ERROR =
	"[EVENTS CREATE] CREATE_EVENT_ATTENDEE_ERROR";
export const CREATE_EVENT_ATTENDEE_SUCCESS =
	"[EVENTS CREATE] CREATE_EVENT_ATTENDEE_SUCCESS";

export function createEventAttendee(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_EVENT_ATTENDEE
		});
		try {
			const { login } = getState();

			let response = await api.sendPost(`/event/attendee`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_EVENT_ATTENDEE_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: CREATE_EVENT_ATTENDEE_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: CREATE_EVENT_ATTENDEE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT =
	"[EVENTS CREATE] IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT";
export const IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT_ERROR =
	"[EVENTS CREATE] IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT_ERROR";
export const IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT_SUCCESS =
	"[EVENTS CREATE] IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT_SUCCESS";

export function importCheckInOut(id, type, models, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT
		});
		try {
			const { login } = getState();

			let response = null;

			const chunk = _.chunk(models, 100);

			for (let i = 0; i < chunk.length; i++) {
				response = await api.sendPost(
					`/event/${id}/attendee/import?type=${type}`,
					chunk[i],
					{
						Authorization: "Bearer " + login.token
					}
				);
			}

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: IMPORT_EVENT_ATTENDEE_CHECK_IN_OUT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const DELETE_EVENT_ATTENDEE = "[EVENTS DELETE] DELETE_EVENT_ATTENDEE";
export const DELETE_EVENT_ATTENDEE_ERROR =
	"[EVENTS DELETE] DELETE_EVENT_ATTENDEE_ERROR";
export const DELETE_EVENT_ATTENDEE_SUCCESS =
	"[EVENTS DELETE] DELETE_EVENT_ATTENDEE_SUCCESS";

export function deleteEvent(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_EVENT_ATTENDEE
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/event/attendee/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_EVENT_ATTENDEE_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_EVENT_ATTENDEE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_EVENT_ATTENDEE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
