import * as actions from "./actions";

const initialState = {
	items: [],
	item: null,
	filter: [],
	pages: null,
	count: 0,
	loading: {},
	showDeleteForm: false,
	showEditForm: false
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_USERS: {
			return {
				...state,
				loading: { getAll: true }
			};
		}
		case actions.GET_USERS_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { getAll: false }
			};
		}
		case actions.GET_USERS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { getAll: false }
			};
		}

		case actions.IMPORT_USERS: {
			return {
				...state,
				loading: { import: true }
			};
		}
		case actions.IMPORT_USERS_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { import: false }
			};
		}
		case actions.IMPORT_USERS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { import: false }
			};
		}

		case actions.DELETE_USER: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_USER_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { delete: false }
			};
		}
		case actions.DELETE_USER_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}

		case actions.EDIT_USER: {
			return {
				...state,
				loading: { edit: true }
			};
		}
		case actions.EDIT_USER_SUCCESS: {
			return {
				...state,
				// items: payload ? payload : [],
				loading: { edit: false }
			};
		}
		case actions.EDIT_USER_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { edit: false }
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
