import React, { useEffect, useState } from "react";
import { reset, change } from "redux-form";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Create from "./components/Create";
import validate from "./components/validate";
import {
	createUserForCompany,
	clearValues
} from "containers/User/containers/Create/actions";
import { useParams } from "react-router-dom";
import { upload } from "redux/actions/upload";
import dayjs from "dayjs";
import customParse from "dayjs/plugin/customParseFormat";

dayjs.extend(customParse);

const ExpositorRegisterId = ({ history }) => {
	const [error, setError] = useState("");
	const dispatch = useDispatch();

	const { id, type } = useParams();

	useEffect(() => {
		dispatch(clearValues());
		dispatch(change("expositor_form", "type", type));
		dispatch(change("expositor_form", "loading", false));
	}, [type]);

	const onSubmit = async values => {
		const errors = validate(values);

		if (!_.isEmpty(errors)) {
			setError("Por favor, preencha todos os campos corretamente.");
			return;
		}

		if (values.type === "professional") {
			if (values.architect) {
				values.profession = "Arquiteto";
			}

			if (values.decorator) {
				values.interiorDecorator = "Decorador de interiores";
			}
		}

		const data = {
			type: values.type,
			firstName: values.name,
			lastName: values.lastName,
			badgeName: values.badgeName,
			document: values.cpf,
			companyName: values.companyName || undefined,
			companyDocument: values.companyDocument || values.cnpj || undefined,
			email: values.email,
			phoneNumber:
				values.phone && values.type === "importer"
					? values.phone.match(/\d/g).join("")
					: `+55${values.phone.match(/\d/g).join("")}`,
			birthDate:
				values.birthDate &&
				dayjs(values.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
			role: "USER",
			gender: values.gender && values.gender.value,
			profession: values.profession || undefined,
			address: {
				street: values.street,
				number: values.number,
				complement: values.complement,
				zipCode: values.zipCode,
				city: values.city,
				state: values.state?.value,
				country: values.country || "BR"
			},
			knowAbout: values.knowAbout,
			cnpj: values.cnpj || undefined,
			professionalFiles: []
		};

		for (const file of values.professionalFiles || []) {
			const fileUrl = await new Promise((resolve, reject) => {
				dispatch(
					upload("files", file, (err, url) => {
						if (err) {
							console.error("Erro no upload:", error);
							alert("Erro no upload: " + JSON.stringify(error));
							reject(err);
						} else {
							console.log("upload ok");
							resolve(url);
						}
					})
				);
			});

			data.professionalFiles.push({ url: fileUrl });
		}

		try {
			dispatch(change("expositor_form", "loading", true));
			dispatch(
				createUserForCompany(id, data, (err, user) => {
					if (err) {
						console.log(err);
					} else {
						dispatch(reset("expositor_form"));
						dispatch(change("expositor_form", "done", true));
					}

					dispatch(change("expositor_form", "loading", false));
				})
			);
		} catch (err) {
			console.error("Houve um erro na submissão:", err);

			dispatch(change("expositor_form", "loading", false));
		}
	};

	const handleReload = () => {
		location.reload();
	};

	return (
		<div className="account" style={{ flexDirection: "column" }}>
			<div className="account__banner" />
			<div className="account__wrapper">
				<div className="account__card" style={{ paddingBottom: 8 }}>
					<div className="account__logo__head">
						<div className="account__logo" />
						{/* <div className="account__date" /> */}
					</div>

					{error ? (
						<div>
							<h3 className="account__title">{error}</h3>
							<button
								type="button"
								className="btn btn-primary account__btn account__btn--small"
								onClick={() => {
									handleReload();
								}}
							>
								Voltar
							</button>
						</div>
					) : (
						<Create onSubmit={onSubmit} history={history} />
					)}
				</div>
			</div>
		</div>
	);
};

export default ExpositorRegisterId;
