import React from "react";
import { reduxForm } from "redux-form";
import validate from "./validate";

const FormOne = props => {
	const { handleSubmit, change } = props;

	const handleSelect = event => {
		change("type", event.target.name);
		handleSubmit(event.target.name);
	};

	return (
		<div>
			<div className="flex-center">
				<h3 className="account__title">Selecione uma opção:</h3>
			</div>
			<button
				name="exhibitor"
				className="btn btn-primary account__btn account__btn--small"
				onClick={handleSelect}
			>
				Expositor
			</button>
			<button
				name="store"
				className="btn btn-primary account__btn account__btn--small"
				onClick={handleSelect}
			>
				Lojista
			</button>
			<button
				name="press"
				className="btn btn-primary account__btn account__btn--small"
				onClick={handleSelect}
			>
				Imprensa
			</button>
			<button
				name="importer"
				className="btn btn-primary account__btn account__btn--small"
				onClick={handleSelect}
			>
				Importador
			</button>
			<button
				name="guest"
				className="btn btn-primary account__btn account__btn--small"
				onClick={handleSelect}
			>
				Visitante
			</button>
		</div>
	);
};

export default reduxForm({
	form: "expositor_form",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate: v => validate(v, 1)
})(FormOne);
