import React, { useState } from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import validate from "./validate";
import { CEP, CNPJ, CPF, DATE, PHONE } from "components/NumberFormat";
import Input from "components/ReduxForm/Input";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import renderCheckBoxField from "shared/components/form/CheckBox";
import renderDatePickerField from "shared/components/form/DatePicker";
import states from "assets/states.json";
import renderSelectField from "shared/components/form/Select";

const FormTwo = props => {
	const {
		handleSubmit,
		pristine,
		submitting,
		previousPage,
		onFormTwoSubmit,
		change
	} = props;

	const [zipCodeLoading, setZipCodeLoading] = useState(false);

	const handleZipCodeChange = zipCode => {
		if (zipCode && String(zipCode).match(/\d/g).length === 8) {
			setZipCodeLoading(true);
			fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
				.then(res => res.json())
				.then(json => {
					if (json) {
						change("street", json.logradouro);
						change("complement", json.complemento);
						change("neighborhood", json.bairro);
						change("city", json.localidade);
						change(
							"state",
							states.find(c => c.value === json.uf)
						);
					}
					setZipCodeLoading(false);
				})
				.catch(err => {
					console.log(err);
					setZipCodeLoading(false);
				});
		}
	};

	const state = useSelector(state => state);

	const selector = formValueSelector("expositor_form");

	const loading = selector(state, "loading");

	const type = selector(state, "type");

	const handleNextClick = e => {
		e.preventDefault();
		handleSubmit(onFormTwoSubmit);
	};

	return (
		<form className="form" onSubmit={handleNextClick}>
			<div className="form__form-group">
				{["exhibitor", "store", "press"].includes(type) && (
					<>
						<span className="form__form-group-label">Empresa</span>
						<div className="form__form-group-field">
							<Field
								name="companyName"
								type="text"
								placeholder="Nome da empresa"
								autoFocus={true}
								component={Input}
								key="companyName"
							/>
						</div>
						<br />
						<span className="form__form-group-label">CNPJ</span>
						<div className="form__form-group-field">
							<Field
								name="cnpj"
								type="text"
								placeholder="CNPJ da empresa"
								component={CNPJ}
							/>
						</div>
					</>
				)}
				<br />
				<div style={{ display: "flex" }}>
					<div style={{ flex: 1, marginRight: 4 }}>
						<span className="form__form-group-label">Nome</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								type="text"
								placeholder="Seu Nome"
								autoFocus={true}
								component={Input}
								key="firstName"
							/>
						</div>
					</div>
					<div style={{ flex: 1, marginLeft: 4 }}>
						<span className="form__form-group-label">Sobrenome</span>
						<div className="form__form-group-field">
							<Field
								name="lastName"
								type="text"
								placeholder="Seu Sobrenome"
								component={Input}
								key="lastName"
							/>
						</div>
					</div>
				</div>
				<br />
				<span className="form__form-group-label">Nome no crachá</span>
				<div className="form__form-group-field">
					<Field
						name="badgeName"
						type="text"
						placeholder="Nome que aparecerá no crachá"
						component={Input}
						key="badgeName"
					/>
				</div>

				<br />
				<span className="form__form-group-label">E-mail</span>
				<div className="form__form-group-field">
					<Field
						name="email"
						type="email"
						placeholder="Seu e-mail"
						component={Input}
						key="email"
					/>
				</div>

				{type === "importer" && (
					<>
						<br />
						<span className="form__form-group-label">Empresa</span>
						<div className="form__form-group-field">
							<Field
								name="companyName"
								type="text"
								placeholder="Nome da empresa"
								autoFocus={true}
								component={Input}
								key="companyName"
							/>
						</div>
					</>
				)}

				{type !== "importer" && (
					<>
						<br />
						<span className="form__form-group-label">Cargo/Profissão</span>
						<div className="form__form-group-field">
							<Field
								name="profession"
								placeholder="Seu cargo/profissão"
								component={Input}
							/>
						</div>
					</>
				)}

				{type !== "importer" && (
					<>
						<br />
						<div style={{ display: "flex" }}>
							<div style={{ flex: 1, marginRight: 4 }}>
								<span className="form__form-group-label">CPF</span>
								<div className="form__form-group-field">
									<Field
										name="cpf"
										type="text"
										placeholder="Seu CPF"
										component={CPF}
									/>
								</div>
							</div>
							<div style={{ flex: 1, marginLeft: 4 }}>
								<span className="form__form-group-label">
									Data de Nascimento
								</span>
								<div className="form__form-group-field">
									<Field
										name="birthDate"
										type="text"
										placeholder="Data de Nascimento"
										component={DATE}
									/>
								</div>
							</div>
						</div>
					</>
				)}

				<br />
				{type === "importer" ? (
					<>
						<span className="form__form-group-label">
							WhatsApp com DDI do País
						</span>
						<div className="form__form-group-field">
							<Field
								name="phone"
								type="text"
								placeholder="Ex.: 5511999999999"
								component={Input}
							/>
						</div>
					</>
				) : (
					<div style={{ display: "flex" }}>
						<div style={{ flex: 1, marginRight: 4 }}>
							<span className="form__form-group-label">WhatsApp</span>
							<div className="form__form-group-field">
								<Field
									name="phone"
									type="text"
									placeholder="Seu WhatsApp"
									component={PHONE}
								/>
							</div>
						</div>

						<div style={{ flex: 1, marginLeft: 4 }}>
							<span className="form__form-group-label">CEP</span>
							<div className="form__form-group-field">
								<Field
									name="zipCode"
									placeholder="Ex.: 00000-000"
									component={CEP}
									onChange={handleZipCodeChange}
								/>
							</div>
						</div>
					</div>
				)}

				{type !== "importer" && (
					<>
						<br />
						<div style={{ display: "flex" }}>
							<div style={{ flex: 3, marginRight: 4 }}>
								<span className="form__form-group-label">Cidade</span>
								<div className="form__form-group-field">
									<Field
										name="city"
										type="text"
										placeholder="Ex.: Florianópolis"
										component={Input}
										key="city"
									/>
								</div>
							</div>
							<div style={{ flex: 1, marginLeft: 4 }}>
								<span className="form__form-group-label">Estado</span>
								<div className="form__form-group-field">
									<Field
										placeholder="Selecione"
										name="state"
										component={renderSelectField}
										type="text"
										options={states}
									/>
								</div>
							</div>
						</div>
					</>
				)}

				{type === "professional" && (
					<>
						<br />
						<span className="form__form-group-label">Profissão</span>
						<br />
						<div
							className="form__form-group-field"
							style={{ justifyContent: "space-around" }}
						>
							<Field
								name="architect"
								component={renderCheckBoxField}
								type="text"
								label="Arquiteto(a)"
								onChange={() => {
									change("interiorDecorator", false);
								}}
							/>
							<Field
								name="interiorDecorator"
								component={renderCheckBoxField}
								type="text"
								label="Decorador(a) de interiores"
								onChange={() => {
									change("architect", false);
								}}
							/>
						</div>
					</>
				)}

				{type === "importer" && (
					<>
						<br />
						<span className="form__form-group-label">País</span>
						<div className="form__form-group-field">
							<Field
								name="country"
								type="text"
								placeholder="Ex.: Brasil"
								component={Input}
							/>
						</div>
					</>
				)}

				<br />
				<span className="form__form-group-label">Como soube da feira?</span>
				<div className="form__form-group-field">
					<Field
						name="knowAbout"
						type="text"
						placeholder="Ex.: Redes sociais, amigos, etc."
						component={Input}
					/>
				</div>

				<br />
				<div
					className="form__form-group-field"
					// style={{ justifyContent: "space-around" }}
				>
					<Field
						name={"lgpd"}
						component={renderCheckBoxField}
						type="text"
						label="Autorizo o tratamento dos meus dados pessoais, conforme a Lei Geral de Proteção de Dados (LGPD), para a finalidade de participação no 1º Fórum do Setor de Empresas de Energia Elétrica, que acontecerá nos dias 25 e 26 de março de 2025, em Florianópolis, no Passeio Sapiens."
					/>
				</div>
			</div>
			<div className="account__btn__group">
				<button
					type="button"
					className="btn btn-primary account__btn account__btn--small"
					onClick={previousPage}
				>
					Voltar
				</button>
				<button
					type="submit"
					className="btn btn-primary account__btn account__btn--small"
					disabled={pristine || submitting}
				>
					{loading ? (
						<Spinner type="grow" size="sm" color="dark" />
					) : (
						"Cadastrar"
					)}
				</button>
			</div>
		</form>
	);
};

export default reduxForm({
	form: "expositor_form",
	destroyOnUnmount: false, // Preserve form data
	forceUnregisterOnUnmount: true, // Unregister fields on unmount
	validate: v => validate(v, 2)
})(FormTwo);
