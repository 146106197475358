import { getCompanies } from "containers/Company/containers/Report/actions";
import validate from "containers/User/components/validate";
import ls from "Localization";
import _ from "lodash";
import userGenders from "models/userGenders";
import userProfessions from "models/userProfessions";
import userRole from "models/userRole";
import userStatus from "models/userStatus";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { reset, SubmissionError } from "redux-form";
import CreateUserForm from "../../components/createForm";
import * as actions from "./actions";
import { setValue, editUser } from "../Report/actions";

import Swal from "sweetalert2";
import Edit from "../Edit";
import { upload } from "redux/actions/upload";
import { generateUserTag } from "helpers/tag";

let findCompaniesDebounced = null;

const EmailWarning = ({ isVisible, onClick, data }) => {
	if (!isVisible) return null;

	return (
		<div className="alert alert-warning" role="alert">
			Atenção! Este e-mail já está cadastrado no sistema.{"  "}
			<a onClick={() => onClick(data)} href="#" class="alert-link">
				{" "}
				Clique aqui para editar.
			</a>
		</div>
	);
};

const CpfWarning = ({ isVisible, onClick, data }) => {
	if (!isVisible) return null;

	return (
		<div className="alert alert-warning" role="alert">
			Atenção! Este CPF já está cadastrado no sistema.{"  "}
			<a onClick={() => onClick(data)} href="#" class="alert-link">
				{" "}
				Clique aqui para editar.
			</a>
		</div>
	);
};

function Create({ history }) {
	const dispatch = useDispatch();

	const [showEmailWarning, setShowEmailWarning] = useState(false);
	const [showCpfWarning, setShowCpfWarning] = useState(false);
	const [userData, setUserData] = useState({});

	const { loading, item } = useSelector(s => s.userCreate);

	const { items: companies } = useSelector(s => s.company);
	const { user } = useSelector(s => s.login);

	const load = useCallback(() => {
		dispatch(reset("user_create"));

		if (userData) history.push("/user/" + userData._id);
	}, [dispatch, userData?._id]);

	const onSubmit = async values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		const fileUrls = [];

		if (values.professionalFile && values.professionalFile.length) {
			const filesArray = Array.from(values.professionalFile);
			const uploadPromises = filesArray.map(
				file =>
					new Promise((resolve, reject) => {
						dispatch(
							upload("user", file, (error, url) => {
								if (error) {
									console.error("Erro no upload:", error);
									alert("Erro no upload: " + error.message);
									reject(error);
								} else {
									console.log("Upload OK: ", url);
									fileUrls.push({ url });
									resolve(url);
								}
							})
						);
					})
			);

			try {
				await Promise.all(uploadPromises);
			} catch (error) {
				console.log("Erro durante o upload dos arquivos: ", error);
				return;
			}
		}

		const data = {
			firstName: values.firstName,
			lastName: values.lastName,
			document: values.document && values.document.match(/\d/g).join(""),
			email: values.email,
			phoneNumber:
				values.phoneNumber && `+55${values.phoneNumber.match(/\d/g).join("")}`,
			birthDate: values.birthDate && values.birthDate,
			role: values.role.value,
			gender: values.gender && values.gender.value,
			company: (values.company && values.company.value) || undefined,
			companyName: values.companyName,
			status: values.status.value,
			profession: values.profession?.value || values.profession || undefined,
			professionalFiles: fileUrls
		};

		dispatch(
			actions.createUser(data, (err, user) => {
				if (err) {
					console.log(err);
					if (err.default && err.default === "Email already registered") {
						// Mostrar aviso e permitir edição do usuário existente
						setShowEmailWarning(true);
					} else {
						console.log(err);
						alert(err.default);
					}
				} else {
					dispatch(reset("user_create"));
					history.push("/user/" + user._id);

					if (isReceptionist) {
						generateUserTag(user).then();

						dispatch(editUser(user._id, { ...user, qrPrinted: true }));
					}
				}
			})
		);
	};

	const handleResponse = (
		isEmail,
		err,
		available,
		data,
		setWarning,
		setUserData
	) => {
		if (err) {
			// console.log(`Erro ao verificar ${isEmail ? "e-mail" : "CPF"}:`, err.message);
			setWarning(false);
		} else if (!available) {
			setWarning(true);
			setUserData(data);
		} else {
			setWarning(false);
		}
	};

	const debounceVerifyEmail = _.debounce((value, callback) => {
		dispatch(actions.verifyUser(value, null, null, callback));
	}, 800);

	const debounceVerifyCpf = _.debounce((value, callback) => {
		dispatch(actions.verifyUser(null, value, null, callback));
	}, 800);

	const handleVerification = event => {
		const value = event.currentTarget.value;
		const isEmail = value.includes("@");
		const isCpf = value.length === 14;

		if (isEmail && value.length > 5) {
			debounceVerifyEmail(value, (err, available, data) => {
				handleResponse(
					true,
					err,
					available,
					data,
					setShowEmailWarning,
					setUserData
				);
			});
		} else if (isCpf) {
			debounceVerifyCpf(value, (err, available, data) => {
				handleResponse(
					false,
					err,
					available,
					data,
					setShowCpfWarning,
					setUserData
				);
			});
		}
	};

	const onChange = values => {
		if (values.reset) {
			dispatch(setValue({ item: {} }));
		}

		if (values.company) {
			if (values.company.value === "add") {
				dispatch(setValue({ item: values }));
				history.push("/company/new");
			}
		}

		if (values.companySearch) {
			handleFindCompany(values.companySearch);
		}
	};

	const handleEditClick = data => {
		dispatch(
			setValue({
				showEditForm: true,
				item: data
			})
		);
	};

	useEffect(() => {
		handleFindCompany();
	}, [dispatch]);

	const handleGenerateLink = useCallback(() => {
		const data = user.company;

		const type = { E: "expositor", S: "provider", M: "builder" }[
			data.companyType
		];

		const urlGenerate = `${process.env.REACT_APP_APP_URL}/cadastro/${type}/${data._id}`;

		// const html = (
		// 	<div>
		// 			Aqui está o link para o cadastro: <a href=${urlGenerate} target="_blank">${urlGenerate}</a>
		// 			<button onClick={() => copy(urlGenerate)}>
		// 				<FiCopy />
		// 			</button>
		// 		</div>
		// )

		navigator.clipboard.writeText(urlGenerate);

		Swal.fire({
			title: "Link copiado!",
			html: urlGenerate,
			icon: "success",
			confirmButtonText: "Ok"
		});
	}, [user.company?._id]);

	const handleFindCompany = company => {
		if (findCompaniesDebounced) {
			findCompaniesDebounced.cancel();
		}

		findCompaniesDebounced = _.debounce(
			() =>
				dispatch(
					getCompanies(
						0,
						10,
						`&filters[name]=${company}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar os expositores, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findCompaniesDebounced();
	};

	const isAdmin = useMemo(() => {
		if (!user) return false;

		return user.role.toLowerCase() === "admin";
	}, [user]);

	const isManager = useMemo(() => {
		if (!user) return false;

		return user.role.toLowerCase() === "manager";
	}, [user]);

	const isBuilder = useMemo(() => {
		if (!user) return false;

		return user.role.toLowerCase() === "builder";
	}, [user]);

	const isReceptionist = useMemo(() => {
		if (!user) return false;

		return user.role === "RECEPTIONIST";
	}, [user]);

	const companiesOptions = useMemo(() => {
		return [
			{ value: "add", label: "Adicionar nova" },
			...companies
				.filter(c => isAdmin || isReceptionist || c._id === user.company)
				.map(c => ({
					value: c._id,
					label: c.name
				}))
		];
	}, [isAdmin, isReceptionist, companies]);

	const roleOptions = useMemo(
		() =>
			Object.keys(userRole)
				.filter(
					c =>
						isAdmin || ![userRole.ADMIN, userRole.PROJECT_MANAGER].includes(c)
				)
				.map(c => ({
					value: c,
					label: ls[userRole[c]]
				})),
		[isAdmin]
	);

	const statusOptions = Object.keys(userStatus).map(c => ({
		value: c,
		label: ls[userStatus[c]]
	}));

	const genderOptions = Object.keys(userGenders).map(c => ({
		value: c,
		label: ls[userGenders[c]]
	}));

	const professionOptions = useMemo(() => {
		if (isBuilder)
			Object.keys(userProfessions)
				.filter(c =>
					["Eletricista", "OperadordeTrabalhoemAltura", "Other"].includes(c)
				)
				.map(c => ({
					value: c,
					label: userProfessions[c]
				}));

		return Object.keys(userProfessions)
			.filter(c => !["Eletricista", "OperadordeTrabalhoemAltura"].includes(c))
			.map(c => ({
				value: c,
				label: userProfessions[c]
			}));
	}, [isBuilder]);

	const initialValues = useMemo(() => {
		if (!item)
			return {
				status: statusOptions[1],
				role: {
					value: "USER",
					label: ls[userRole["USER"]]
				}
			};

		return {
			name: item.name,
			lastName: item.lastName,
			document: item.document,
			email: item.email,
			phoneNumber: item.phoneNumber,
			birthDate: item.birthDate,
			role: {
				value: "USER",
				label: ls[userRole["USER"]]
			},
			gender: item.gender,
			status: item.status,
			profession: item.profession,
			company: item.company?.value === "add" ? "" : item.company
		};
	}, [item]);

	return (
		<Container className="dashboard">
			<EmailWarning
				isVisible={showEmailWarning}
				onClick={handleEditClick}
				data={userData}
			/>

			<Row>
				<Col xs={11}>
					<Card>
						<CpfWarning
							isVisible={showCpfWarning}
							onClick={handleEditClick}
							data={userData}
						/>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Cadastrar Usuário</h5>
								{(isManager || isBuilder) && user.company && (
									<button
										className="btn btn-primary"
										onClick={handleGenerateLink}
									>
										Copiar link de cadastro único
									</button>
								)}
							</div>
							<CreateUserForm
								onSubmit={onSubmit}
								loading={loading}
								onChange={onChange}
								roleOptions={roleOptions}
								statusOptions={statusOptions}
								genderOptions={genderOptions}
								companiesOptions={companiesOptions}
								professionOptions={professionOptions}
								initialValues={initialValues}
								isAdmin={isAdmin}
								isManager={isManager}
								isReceptionist={isReceptionist}
								isBuilder={isBuilder}
								handleEmailBlur={handleVerification}
								handleCPFBlur={handleVerification}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Edit load={load} />
		</Container>
	);
}

export default Create;
